import { state } from "@angular/animations";
import { RootState } from "..";
import { UserState } from "./state";
import { createSelector } from "@ngrx/store";

const selectFeature = (state: RootState): UserState => state.user;

export const selectGetLoading = createSelector(selectFeature, state => state.getLoading);

export const selectUpdateLoading = createSelector(selectFeature, state => state.updateLoading);

export const selectUpdateSuccess = createSelector(selectFeature, state => state.updateSuccess);

export const selectLoginLoading = createSelector(selectFeature, state => state.updateLoading && !state.guest);
export const selectLoginSuccess = createSelector(selectFeature, state => state.updateSuccess && !state.guest);

export const selectAccessToken = createSelector(selectFeature, state => state.accessToken);

export const selectRefreshToken = createSelector(selectFeature, state => state.refreshToken);

export const selectUser = createSelector(selectFeature, state => state.user);

export const selectGuest = createSelector(selectFeature, state => state.guest);

export const selectAuth = createSelector({
  accessToken: selectAccessToken,
  guest: selectGuest,
});

export const selectError = createSelector(selectFeature, state => state.error);

export const selectInitialLetters = createSelector(selectFeature, state => {
  if (state.guest) {
    return "";
  } else {
    return `${state.user?.name?.substring(0, 2).toLocaleUpperCase() || state.user?.email?.substring(0, 2).toLocaleUpperCase() || ""}`;
  }
});

export const selectUserCurrentPosition = createSelector(selectFeature, state => state.userCurrentPosition);
export const selectUserAlertLevel = createSelector(selectFeature, state => {
  if (!state.userCurrentPosition || !state.userCurrentPosition.status) {
    return null;
  } else {
    return Math.max(state.userCurrentPosition.status, state.userCurrentPosition.statusPredicted);
  }
});
