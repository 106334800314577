<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<ion-header>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-back-button
                icon="arrow-back-outline"
                [text]="'WATERSHEDS_PAGE.TITLE' | translate"
                (click)="closeIsland()"
            ></ion-back-button>
        </ion-buttons>
        <ion-buttons slot="end">
            <app-hidrometeo-selector />
            <ion-button (click)="resetToIslandsView()"><ion-icon name="home"></ion-icon></ion-button>
            <ion-button appShareUrl="watersheds">
                <ion-icon name="share-outline" slot="icon-only"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content appSuppressScrollEvent class="watersheds">
    <ion-header collapse="condense">
        <ion-toolbar class="toolbar">
            <ion-title class="--h1" [innerHTML]="(island$ | async)?.name"></ion-title>
        </ion-toolbar>
    </ion-header>

    <div
        class="watersheds__description ion-padding --p1"
        [innerHTML]="
            isPitiuses(island$ | async)
                ? ('WATERSHEDS_PAGE.DESCRIPTION_PITIUSES' | translate: { island: (island$ | async)?.name })
                : ('WATERSHEDS_PAGE.DESCRIPTION' | translate: { island: (island$ | async)?.name })
        "
    ></div>

    <ion-list>
        @switch ((dataMode$ | async).variable) {
            @case (DataModeVariable.WaterLevel) {
                @for (watershed of watersheds$ | async | orderByAlertLevel; track watershed.id) {
                    <app-data-card
                        [description]="'WATER_LEVEL' | translate"
                        [dischargePredicted]="watershed.dischargePredicted | parseValueStations: DataModeVariable.Discharge"
                        [hovered]="(hoveredWatershedId$ | async) === watershed.id"
                        [selectedDataModeVariable]="(dataMode$ | async).variable"
                        [status]="watershed.status"
                        [statusPredicted]="watershed.statusPredicted"
                        [subtitle]="watershed.name"
                        [title]="watershed | getValueByDatamode: (dataMode$ | async)"
                        (click)="selectWatershed(watershed.id)"
                        (mouseenter)="onMouseEnter(watershed.id)"
                        (mouseleave)="onMouseLeave(watershed.id)"
                    ></app-data-card>
                }
            }
            @case (DataModeVariable.Precipitation) {
                @for (watershed of watersheds$ | async | orderByPrecipitation: (dataMode$ | async); track watershed.id) {
                    <app-data-card
                        [description]="'PRECIPITATION' | translate"
                        [hovered]="(hoveredWatershedId$ | async) === watershed.id"
                        [selectedDataModeVariable]="(dataMode$ | async).variable"
                        [status]=""
                        [subtitle]="watershed.name"
                        [title]="watershed | getValueByDatamode: (dataMode$ | async)"
                        (click)="selectWatershed(watershed.id)"
                        (mouseenter)="onMouseEnter(watershed.id)"
                        (mouseleave)="onMouseLeave(watershed.id)"
                    ></app-data-card>
                }
            }
        }
    </ion-list>
</ion-content>
