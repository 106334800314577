/* eslint-disable @typescript-eslint/indent */
import { CatchmentDto, DataPointDto, IslandDto, WatershedDto } from "src/app/services/api";
import { RootState } from "..";
import { average } from "src/app/utils/average";
import { WatershedState } from "./state";
import { DefaultProjectorFn, MemoizedSelector, createSelector } from "@ngrx/store";
import { state } from "@angular/animations";

function getLastXHoursData(data: DataPointDto[], numberOfHours: number, modifyData: number = 1): DataPointDto[] {
  return data
    ? Object.entries(
        data.reduce((acum, data) => {
          const now = new Date();
          const d = new Date(data.timestamp);
          // If it's same hour but different day return acum without modifying it
          if (now.getHours() === d.getHours() && now.getDay() !== d.getDay()) return acum;

          const hour = new Date(data.timestamp).getHours();
          if (acum[hour]) acum[hour].push(data.value * modifyData);
          else acum[hour] = [data.value * modifyData];

          return acum;
        }, {}),
      )
        .filter(([key]) => {
          const now = new Date();
          now.setTime(now.getTime() - numberOfHours * 60 * 60 * 1000);
          const min = now.getHours();
          const max = min + numberOfHours;
          return +key <= max && +key > min;
        })
        .reduce((acum, [key, value]) => {
          acum.push({
            timestamp: `${key}:00`,
            value: Math.round(average(value as number[]) * 10) / 10, // Rounding to 1 decimal
          });
          return acum;
        }, [])
    : [];
}

function getWindDirectionXHours(data: DataPointDto[], numberOfHours: number): DataPointDto[] {
  return data
    ? Object.entries(
        data.reduce((acum, data) => {
          const now = new Date();
          const d = new Date(data.timestamp);
          // If it's same hour but different day return acum without modifying it
          if (now.getHours() === d.getHours() && now.getDay() !== d.getDay()) return acum;

          const hour = new Date(data.timestamp).getHours();
          // Save only first direction of each hour
          if (!acum[hour]) acum[hour] = data.value;
          return acum;
        }, {}),
      )
        .filter(([key]) => {
          const now = new Date();
          now.setTime(now.getTime() - numberOfHours * 60 * 60 * 1000);
          const min = now.getHours();
          const max = min + numberOfHours;
          return +key <= max && +key > min;
        })
        .reduce((acum, [key, value]) => {
          acum.push({
            timestamp: `${key}:00`,
            // Make sure is int 0-360, get closest 8 point (N,NE,E...), convert back to degrees
            value: Math.round(((value as number) % 360) / 45) * 45,
          });
          return acum;
        }, [])
    : [];
}

function getWindGustXHours(data: DataPointDto[], numberOfHours: number): DataPointDto[] {
  return data
    ? Object.entries(
        data.reduce((acum, data) => {
          const now = new Date();
          const d = new Date(data.timestamp);
          // If it's same hour but different day return acum without modifying it
          if (now.getHours() === d.getHours() && now.getDay() !== d.getDay()) return acum;

          const hour = new Date(data.timestamp).getHours();
          // Save only first direction of each hour
          if (!acum[hour]) acum[hour] = data.value;
          return acum;
        }, {}),
      )
        .filter(([key]) => {
          const now = new Date();
          now.setTime(now.getTime() - numberOfHours * 60 * 60 * 1000);
          const min = now.getHours();
          const max = min + numberOfHours;
          return +key <= max && +key > min;
        })
        .reduce((acum, [key, value]) => {
          acum.push({
            timestamp: `${key}:00`,
            value: Math.max(value as number),
          });
          return acum;
        }, [])
    : [];
}

const selectFeature = (state: RootState): WatershedState => state.watershed;

export const selectCatchmentWaterLevelStationCode = createSelector(selectFeature, state => state.catchmentWaterLevelStationCode);

export const selectCatchmentWaterLevelStationData = createSelector(
  selectFeature,
  selectCatchmentWaterLevelStationCode,
  (state, stationCode) => state.stationsData[stationCode],
);

export const selectCatchmentPrecipitationStationCode = createSelector(selectFeature, state => state.catchmentPrecipitationStationCode);

export const selectCatchmentPrecipitationData = createSelector(
  selectFeature,
  selectCatchmentPrecipitationStationCode,
  (state, stationCode) => state.stationsData[stationCode]?.precipitation,
);

export const selectIslandsObject = createSelector(selectFeature, state => state.islands);

export const selectWatershedsObject = createSelector(selectFeature, state => state.watersheds);

export const selectAllCatchmentsObject = createSelector(selectFeature, state => state.allCatchments);

export const selectLoading = createSelector(selectFeature, state => state.loading);

export const selectMapLayerConfig = createSelector(selectFeature, state => state.mapLayerConfig);

export const selectRiversLayer = createSelector(selectFeature, state => state.riversLayer);

export const selectMapLayerName = createSelector(selectFeature, state => state.mapLayerConfig.name);

export const selectMapLayerWMS = createSelector(selectFeature, state => state.mapLayerWMS);

export const selectMapLayerWMSName = createSelector(selectFeature, state => state.mapLayerWMS.name);

export const selectDataMode = createSelector(selectFeature, state => state.dataMode);

export const selectDataModeTime = createSelector(selectFeature, state => state.dataMode.time);

export const selectMapLayerWMSTime = createSelector(selectFeature, state => state.mapLayerWMS.time);

export const selectIslandId = createSelector(selectFeature, state => state.islandId);

export const selectWatershedId = createSelector(selectFeature, state => state.watershedId);

export const selectCatchmentId = createSelector(selectFeature, state => state.catchmentId);

export const selectStationCode = createSelector(selectFeature, state => state.stationCode);

export const selectStationtPrecipitationData = createSelector(
  selectFeature,
  selectStationCode,
  (state, stationCode) => state.stationsData[stationCode]?.precipitation,
);

export const selectHoveredIslandId = createSelector(selectFeature, state => state.hoveredIslandId);

export const selectHoveredWatershedId = createSelector(selectFeature, state => state.hoveredWatershedId);

export const selectHoveredCatchmentId = createSelector(selectFeature, state => state.hoveredCatchmentId);

export const selectHoveredStationCode = createSelector(selectFeature, state => state.hoveredStationCode);

export const selectIslands = createSelector(selectIslandsObject, islands => Object.values(islands));

export const selectAllCatchments = createSelector(selectAllCatchmentsObject, allCatchments => Object.values(allCatchments));

export const selectIsland = createSelector(selectFeature, state => state.islands[state.islandId]);

export const selectWatersheds = createSelector(selectWatershedsObject, selectIslandId, (watersheds, islandId) =>
  Object.values(watersheds).filter(watershed => watershed.islandId === islandId),
);

export const selectWatershed = createSelector(selectFeature, state => state.watersheds[state.watershedId]);

export const selectWatershedById = createSelector(selectFeature, state => state.watersheds[state.watershedId]);

export const selectWatershedCatchment = createSelector(selectFeature, state => state.allCatchments[state.catchmentId]);

export const selectIslandCatchment = createSelector(selectFeature, state => state.allCatchments[state.catchmentId]);

export const selectWatershedCatchmentById = (
  catchmentId: number,
): MemoizedSelector<RootState, CatchmentDto, DefaultProjectorFn<CatchmentDto>> =>
  createSelector(selectFeature, state => state.allCatchments[catchmentId]);

export const selectIslandCatchmentById = (
  catchmentId: number,
): MemoizedSelector<RootState, CatchmentDto, DefaultProjectorFn<CatchmentDto>> =>
  createSelector(selectFeature, state => state.allCatchments[catchmentId]);

export const selectIslandWatershedById = (
  watershedId: number,
): MemoizedSelector<RootState, WatershedDto, DefaultProjectorFn<WatershedDto>> =>
  createSelector(selectFeature, state => state.watersheds[watershedId]);

export const selectWatershedCatchmentData = createSelector(selectFeature, state => state.catchmentsData[state.catchmentId]);

export const selectWatershedCatchments = createSelector(selectAllCatchmentsObject, selectWatershedId, (allCatchments, watershedId) =>
  Object.values(allCatchments).filter(catchment => +catchment.watershedId === +watershedId),
);

export const selectIslandCatchments = createSelector(selectAllCatchmentsObject, selectIslandId, (allCatchments, islandId) =>
  Object.values(allCatchments).filter(catchment => catchment.islandId === islandId),
);

export const selectWatershedCatchmentStations = createSelector(selectFeature, state => state.catchmentStations[state.catchmentId]);

// Get hydro stations or hydrometeo stations within the catchment (but not those meteo-associated to this catchment)
export const selectWatershedCatchmentHydroStations = createSelector(
  selectFeature,
  state =>
    state.catchmentStations[state.catchmentId]?.filter(
      station =>
        // station.type === "1" || station.type === "2"),
        station.type === "1" || (station.type === "2" && station.catchmentId == state.catchmentId),
    ),
);

export const selectWatershedCatchmentMeteoStations = createSelector(
  selectFeature,
  state => state.catchmentStations[state.catchmentId]?.filter(station => station.type === "0" || station.type === "2"),
);

export const selectStation = createSelector(
  selectFeature,
  state => state.catchmentStations[state.catchmentId]?.find(station => station.id === state.stationCode),
);

export const selectStationData = createSelector(selectFeature, selectStationCode, (state, stationCode) => state.stationsData[stationCode]);

export const selectHasStationDataHumidity = createSelector(
  selectFeature,
  state => !!state.stationsData[state.stationCode]?.humidity?.length,
);

export const selectHasStationDataWindDirection = createSelector(
  selectFeature,
  state => !!state.stationsData[state.stationCode]?.windDirection?.length,
);

export const selectEmptyStationDataHumidity = createSelector(
  selectFeature,
  // If station has humidity data (length != 0), check if every element is NULL
  state =>
    !!state.stationsData[state.stationCode]?.humidity?.length
      ? !state.stationsData[state.stationCode]?.humidity?.every(element => !element.value)
      : false,
);

export const selectHasStationDataWind = createSelector(selectFeature, state => !!state.stationsData[state.stationCode]?.wind?.length);
export const selectHasStationDataWindGust = createSelector(
  selectFeature,
  state => !!state.stationsData[state.stationCode]?.windGust?.length,
);

export const selectEmptyStationDataWind = createSelector(
  selectFeature,
  // If station has data (length != 0), check if every element is NULL
  state =>
    !!state.stationsData[state.stationCode]?.wind?.length
      ? !state.stationsData[state.stationCode]?.wind?.every(element => !element.value)
      : false,
);

export const selectHasStationDataTemperature = createSelector(
  selectFeature,
  state => !!state.stationsData[state.stationCode]?.temperature?.length,
);

export const selectEmptyStationDataTemperature = createSelector(
  selectFeature,
  // If station has data (length != 0), check if every element is NULL
  state =>
    !!state.stationsData[state.stationCode]?.temperature?.length
      ? !state.stationsData[state.stationCode]?.temperature?.every(element => !element.value)
      : false,
);

export const selectHasStationDataPrecipitation = createSelector(
  selectFeature,
  state => !!state.stationsData[state.stationCode]?.precipitation60?.length,
);

export const selectStationDataHumidity = createSelector(selectFeature, state =>
  state.stationsData[state.stationCode]?.humidity ? +(state.stationsData[state.stationCode]?.humidity[0]?.value * 100) || 0 : 0,
);

export const selectStationDataWind = createSelector(selectFeature, state =>
  state.stationsData[state.stationCode]?.wind ? +state.stationsData[state.stationCode]?.wind[0]?.value || 0 : 0,
);

export const selectStationDataWindDirection = createSelector(selectFeature, state =>
  state.stationsData[state.stationCode]?.windDirection
    ? Math.round(((state.stationsData[state.stationCode]?.windDirection[0]?.value as number) % 360) / 45) * 45 || 0
    : 0,
);

export const selectStationDataWindGust = createSelector(selectFeature, state =>
  state.stationsData[state.stationCode]?.windGust ? +state.stationsData[state.stationCode]?.windGust[0]?.value || 0 : 0,
);

export const selectStationDataTemperature = createSelector(selectFeature, state =>
  state.stationsData[state.stationCode]?.temperature ? +state.stationsData[state.stationCode]?.temperature[0]?.value || 0 : 0,
);

export const selectStationDataPrecipitation = createSelector(selectFeature, state =>
  state.stationsData[state.stationCode]?.precipitation60 ? +state.stationsData[state.stationCode]?.precipitation60[0]?.value || 0 : 0,
);

export const catchmentPrecipitationStation = createSelector(
  selectFeature,
  selectCatchmentId,
  selectCatchmentPrecipitationStationCode,
  (state, catchmentId, selectPrecipitationStationCode) =>
    state.catchmentStations[catchmentId]?.find(element => element.id === selectPrecipitationStationCode),
);

export const selectStationDataHumidityHours = createSelector(selectFeature, state =>
  getLastXHoursData(state.stationsData[state.stationCode]?.humidity, 5, 100),
);

export const selectStationDataWindHours = createSelector(selectFeature, state =>
  getLastXHoursData(state.stationsData[state.stationCode]?.wind, 5),
);

export const selectStationDataWindGustHours = createSelector(selectFeature, state =>
  getWindGustXHours(state.stationsData[state.stationCode]?.windGust, 5),
);

export const selectStationDataWindDirectionHours = createSelector(selectFeature, state =>
  getWindDirectionXHours(state.stationsData[state.stationCode]?.windDirection, 5),
);

export const selectStationDataTemperatureHours = createSelector(selectFeature, state =>
  getLastXHoursData(state.stationsData[state.stationCode]?.temperature, 5),
);

export const selectStationDataPrecipitationHours = createSelector(selectFeature, state =>
  getLastXHoursData(state.stationsData[state.stationCode]?.precipitation60, 24),
);

export const selectCatchmentBlackspots = createSelector(selectFeature, state => state.catchmentBlackspots[state.catchmentId]);

export const selectBlackspotData = createSelector(selectFeature, state => state.blackspotsData[state.blackspotId]);

export const selectBlackspotId = createSelector(selectFeature, state => state.blackspotId);

export const selectBlackspot = createSelector(
  selectFeature,
  state => state.catchmentBlackspots[state.catchmentId]?.find(blackspot => blackspot.id === state.blackspotId),
);

export const selectLastUpdateLayerWMS = createSelector(selectFeature, state => {
  if (state.lastUpdatesLayerWMS?.length) {
    const aemetLastHour = state.lastUpdatesLayerWMS[0].hora_darrera_imatge;

    const AA = aemetLastHour.slice(0, 2);

    let yearNumber = parseInt(AA);
    yearNumber += 2000;

    const year = yearNumber.toString();
    const month = aemetLastHour.substring(2, 4);
    const day = aemetLastHour.substring(4, 6);
    const hour = aemetLastHour.substring(6, 8);
    const minutes = aemetLastHour.substring(8, 10);

    const lastHourDate = `${year}-${month}-${day}T${hour}:${minutes}:00Z`;
    return new Date(lastHourDate).toISOString();
  } else {
    return "";
  }
});
