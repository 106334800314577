import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import * as WatershedActions from "src/app/store/watershed/actions";
import * as FromWatershed from "src/app/store/watershed/selectors";
import * as FromApp from "src/app/store/app/selectors";
import { Store } from "@ngrx/store";
import { RootState } from "src/app/store";
import { Observable, Subject, filter, takeUntil, tap, withLatestFrom } from "rxjs";
import { CatchmentDto, IslandDto, WatershedDto } from "src/app/services/api";
import { TranslateService } from "@ngx-translate/core";
import { DataModeTime, DataModeVariable } from "src/app/enums";
import { GROUPPED_CATCHMENTS } from "src/app/constants";
import { DataMode } from "src/app/interfaces";

@Component({
  selector: "app-watershed",
  templateUrl: "./watershed.component.html",
  styleUrls: ["./watershed.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WatershedComponent {
  public dataModeVariable: typeof DataModeVariable = DataModeVariable;
  public dataModeTime: typeof DataModeTime = DataModeTime;
  public readonly dataMode$: Observable<DataMode> = this.store.select(FromWatershed.selectDataMode);

  public readonly watershedId$: Observable<number> = this.store.select(FromWatershed.selectWatershedId);
  public readonly catchmentId$: Observable<number> = this.store.select(FromWatershed.selectCatchmentId);
  public readonly islandId$: Observable<string> = this.store.select(FromWatershed.selectIslandId);
  public readonly watershed$: Observable<WatershedDto> = this.store.select(FromWatershed.selectWatershed);
  public readonly watershedCatchments$: Observable<CatchmentDto[]> = this.store.select(FromWatershed.selectWatershedCatchments);
  public readonly hoveredCatchmentId$: Observable<number> = this.store.select(FromWatershed.selectHoveredCatchmentId);

  public readonly refreshState$: Observable<boolean> = this.store.select(FromApp.selectRefreshState);

  private unsubscribeSubject$: Subject<void>;

  constructor(
    private readonly store: Store<RootState>,
    public readonly translateService: TranslateService,
  ) {}

  public ionViewDidEnter(): void {
    this.unsubscribeSubject$ = new Subject();

    this.watershedId$
      .pipe(
        takeUntil(this.unsubscribeSubject$),
        filter(watershedId => !!watershedId),
        withLatestFrom(this.islandId$),
        tap(([watershedId, islandId]) => {
          this.store.dispatch(WatershedActions.loadAllCatchments({ islandId: islandId }));
        }),
      )
      .subscribe();

    this.refreshState$
      .pipe(
        takeUntil(this.unsubscribeSubject$),
        filter(refreshState => !!refreshState),
        withLatestFrom(this.watershedId$, this.islandId$),
        filter(([, watershedId]) => !!watershedId),
        tap(([, watershedId, islandId]) => {
          this.store.dispatch(WatershedActions.loadAllCatchments({ islandId: islandId }));
        }),
      )
      .subscribe();
  }

  public ionViewDidLeave(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  public resetToIslandsView(): void {
    this.store.dispatch(WatershedActions.setWatershedId({ watershedId: null }));
    this.store.dispatch(WatershedActions.setIslandId({ islandId: null }));
    this.store.dispatch(WatershedActions.resetToIslandsView());
  }

  public correctNegativeValues(value: number): number {
    return Math.max(value, 0);
  }

  public setCatchmentTitle(catchment: IslandDto | WatershedDto | CatchmentDto): string {
    let catchmentText: string;
    if (catchment.name === "Gros" || catchment.name === "Major") {
      catchmentText = this.translateService.instant("ZONE_NAMES.TORRENT") + catchment.name;
    } else if (
      catchment.name.charAt(0) === "A" ||
      catchment.name.charAt(0) === "E" ||
      catchment.name.charAt(0) === "I" ||
      catchment.name.charAt(0) === "O" ||
      catchment.name.charAt(0) === "U"
    ) {
      catchmentText = this.translateService.instant("ZONE_NAMES.TORRENT_D") + catchment.name;
    } else {
      catchmentText = this.translateService.instant("ZONE_NAMES.TORRENT_DE") + catchment.name;
    }
    GROUPPED_CATCHMENTS.forEach(catchmentId => {
      if (catchmentId === catchment.id) catchmentText = catchment.name;
    });
    return catchmentText;
  }

  public closeWatershed(): void {
    this.store.dispatch(WatershedActions.setWatershedId({ watershedId: null }));
  }

  public selectCatchment(catchmentId: string | number): void {
    this.store.dispatch(WatershedActions.setCatchmentId({ catchmentId: +catchmentId }));
  }

  public onMouseEnter(catchmentId: string | number): void {
    this.store.dispatch(WatershedActions.setHoveredCatchmentId({ catchmentId: +catchmentId, hoveredCatchmentId: +catchmentId }));
  }

  public onMouseLeave(catchmentId: string | number): void {
    this.store.dispatch(WatershedActions.setHoveredCatchmentId({ catchmentId: +catchmentId, hoveredCatchmentId: null }));
  }
}
