/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pipe, PipeTransform } from "@angular/core";
import { DataModeTime } from "src/app/enums";
import { DataMode } from "src/app/interfaces";
import { CatchmentDto, IslandDto, WatershedDto } from "src/app/services/api";

@Pipe({ name: "orderByPrecipitation" })
export class OrderByPrecipitationPipe implements PipeTransform {
  public transform(elements: (IslandDto | WatershedDto | CatchmentDto)[], dataMode: DataMode): (IslandDto | WatershedDto | CatchmentDto)[] {
    if (!elements) return [];
    return [...elements].sort((a, b) => {
      let a_prec, b_prec;
      switch (dataMode.time) {
        case DataModeTime.Time24h:
          a_prec = a.precipitationLast24h;
          b_prec = b.precipitationLast24h;
          break;
        case DataModeTime.Time1h:
        default:
          a_prec = a.precipitationLast1h;
          b_prec = b.precipitationLast1h;
          break;
      }
      if (!this.isNumber(a_prec) && !this.isNumber(b_prec)) return a.name.localeCompare(b.name);
      else if (!this.isNumber(a_prec)) return 1;
      else if (!this.isNumber(b_prec)) return -1;
      const result = b_prec - a_prec;
      return result ? result : a.name.localeCompare(b.name);
    });
  }

  private isNumber(value: any): boolean {
    return typeof value === "number";
  }
}
