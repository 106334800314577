import { Pipe, PipeTransform } from "@angular/core";
import { DataModeTime, DataModeVariable } from "src/app/enums";
import { DataMode } from "src/app/interfaces";
import { IslandDto, WatershedDto, CatchmentDto } from "src/app/services/api";
import { ParseValueStationsPipe } from "../parse-value-stations/parse-value-stations.pipe";

@Pipe({
  name: "getValueByDatamode",
})
export class GetValueByDatamodePipe implements PipeTransform {
  constructor(private readonly parseValueStations: ParseValueStationsPipe) {}

  public transform(element: IslandDto | WatershedDto | CatchmentDto, dataMode: DataMode): string | null {
    switch (dataMode.variable) {
      case DataModeVariable.Precipitation:
        switch (dataMode.time) {
          case DataModeTime.Time1h:
            return this.parseValueStations.transform(element.precipitationLast1h, dataMode.variable);
          case DataModeTime.Time24h:
            return this.parseValueStations.transform(element.precipitationLast24h, dataMode.variable);
          default:
            return this.parseValueStations.transform(element.precipitationLast1h, dataMode.variable);
        }
      case DataModeVariable.Discharge:
        return this.parseValueStations.transform(element.dischargePredicted, dataMode.variable);
      case DataModeVariable.WaterLevel:
      default:
        return this.parseValueStations.transform(element.waterLevel, dataMode.variable);
    }
  }
}
