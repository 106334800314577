/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pipe, PipeTransform } from "@angular/core";
import { DataModeTime } from "src/app/enums";
import { DataMode } from "src/app/interfaces";
import { StationDto } from "src/app/services/api";

@Pipe({ name: "orderStationsByPrecipitation" })
export class OrderStationsByPrecipitationPipe implements PipeTransform {
  public transform(elements: StationDto[], dataMode: DataMode): StationDto[] {
    if (!elements) return [];
    return ([...elements] || []).sort((a, b) => {
      let a_prec, b_prec;
      switch (dataMode.time) {
        case DataModeTime.Time24h:
          a_prec = a.precipitationLast24h;
          b_prec = b.precipitationLast24h;
          break;
        case DataModeTime.Time1h:
        default:
          a_prec = a.precipitationLast1h;
          b_prec = b.precipitationLast1h;
          break;
      }
      if (!this.isNumber(a_prec) && !this.isNumber(b_prec)) return a.id.localeCompare(b.id);
      else if (!this.isNumber(a_prec)) return 1;
      else if (!this.isNumber(b_prec)) return -1;
      const result = b_prec - a_prec;
      return result ? result : a.id.localeCompare(b.id);
    });
  }

  private isNumber(value: any): boolean {
    return typeof value === "number";
  }
}
