/* eslint-disable @typescript-eslint/no-explicit-any */
import { Inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { Observable, tap, withLatestFrom } from "rxjs";
import * as AppActions from "src/app/store/app/actions";
import * as FromApp from "src/app/store/app/selectors";
import { RootState } from "..";
import { REFRESH_TIME_SECONDS } from "src/app/constants";
import { DOCUMENT } from "@angular/common";

@Injectable()
export class AppEffects {
  public incrementRefreshCounter$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppActions.incrementRefreshCounter),
        withLatestFrom(this.store.select(FromApp.selectRefreshCount)),
        tap(([, refreshCount]) => {
          if (refreshCount === REFRESH_TIME_SECONDS) {
            this.store.dispatch(AppActions.refreshState());
          }
        }),
      ),
    { dispatch: false },
  );

  public refreshState$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppActions.refreshState),
        tap(() => {
          this.store.dispatch(AppActions.setRefreshState({ refreshState: false }));
        }),
      ),
    { dispatch: false },
  );

  public switchModalWidth$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppActions.switchModalWidth),
        withLatestFrom(this.store.select(FromApp.selectModalWidth)),
        tap(([, modalWidth]) => {
          this.document.documentElement.style.setProperty("--modal-width", modalWidth);
          this.document.querySelector(".layers-manager__content__left").setAttribute("widened", modalWidth === "900px" ? "true" : "false");
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootState>,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {}
}
