import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core";
import { AlertLevels, DataPointDto, StationDto } from "src/app/services/api";
import { Chart, ChartConfiguration, ChartType, Color, FontSpec, Plugin } from "chart.js/auto";
import { FormControl, FormGroup } from "@angular/forms";
import "chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm";
import { WIND_SPEED } from "src/app/constants";
import { WindSpeedAlertLevels } from "src/app/interfaces";

const primaryLineColor: Color = "#1d4780";
const secondaryLineColor: Color = "#4d89db";
const labelsColor: Color = "#868782";
const gridLinesColor: Color = "#d9d9d9";
const font: Partial<FontSpec> = { family: "AppFont", weight: "normal", size: 12 };

export const CHART_LEVEL4_ZONE_COLOR = "rgba(197, 20, 62, 0.4)";
export const CHART_LEVEL3_ZONE_COLOR = "rgba(230, 126, 20, 0.4)";
export const CHART_LEVEL2_ZONE_COLOR = "rgba(230, 183, 16, 0.4)";
export const CHART_LEVEL1_ZONE_COLOR = "rgba(20, 197, 148, 0.3)";

export const CHART_CURRENT_TIME_COLOR = gridLinesColor; // "rgba(211, 211, 211, 1)";
export const CHART_CURRENT_TIME_WIDTH = 5; // "rgba(211, 211, 211, 1)";

@Component({
  selector: "app-chart",
  templateUrl: "./chart.component.html",
  styleUrls: ["./chart.component.scss"],
})
export class ChartComponent implements OnChanges, AfterViewInit {
  @ViewChild("chartContainer") private readonly chartContainer!: ElementRef<HTMLCanvasElement>;

  @Input() public data: DataPointDto[];
  @Input() public secondaryData?: DataPointDto[];
  @Input() public dataLabel: string;
  @Input() public secondaryDataLabel?: string;
  @Input() public alertLevels?: AlertLevels;
  @Input() public windSpeedAlertLevels?: WindSpeedAlertLevels;
  @Input() public chartType: ChartType;
  @Input() public stations?: StationDto[];
  @Input() public varType?: string;
  @Input() public selectedStationCode?: string;
  @Input() public currentTime: Date;
  @Input() public precipitationLast1h?: string;
  @Input() public precipitationLast24h?: string;

  @Output() public readonly selectStation: EventEmitter<string> = new EventEmitter();

  public form!: FormGroup;

  private chart?: Chart;

  private drawAlertLevelsPlugin: Plugin = {
    id: "1",
    beforeDraw: (): void => {
      if (this.chart) {
        const {
          ctx,
          chartArea: { left, top, right, bottom },
          scales: { y },
        } = this.chart;

        let level1 = null;
        let level2 = null;
        let level3 = null;
        if (!!this.alertLevels?.level1Centimeters) {
          level1 = y.getPixelForValue(this.alertLevels.level1Centimeters / 100);
        } else if (!!this.alertLevels?.level1Discharge) {
          level1 = y.getPixelForValue(this.alertLevels.level1Discharge);
        }
        if (!!this.alertLevels?.level2Centimeters) {
          level2 = y.getPixelForValue(this.alertLevels.level2Centimeters / 100);
        } else if (!!this.alertLevels?.level2Discharge) {
          level2 = y.getPixelForValue(this.alertLevels.level2Discharge);
        }
        if (!!this.alertLevels?.level3Centimeters) {
          level3 = y.getPixelForValue(this.alertLevels.level3Centimeters / 100);
        } else if (!!this.alertLevels?.level3Discharge) {
          level3 = y.getPixelForValue(this.alertLevels.level3Discharge);
        }

        if (this.varType === WIND_SPEED) {
          if (!!this.windSpeedAlertLevels?.level1WindSpeed) level1 = y.getPixelForValue(this.windSpeedAlertLevels.level1WindSpeed);
          if (!!this.windSpeedAlertLevels?.level2WindSpeed) level2 = y.getPixelForValue(this.windSpeedAlertLevels.level2WindSpeed);
          if (!!this.windSpeedAlertLevels?.level3WindSpeed) level3 = y.getPixelForValue(this.windSpeedAlertLevels.level3WindSpeed);
        }
        level3 = level3 || top;
        level2 = level2 || level3;
        level1 = level1 || level2;
        ctx.save();

        ctx.fillStyle = CHART_LEVEL1_ZONE_COLOR;
        ctx.fillRect(left, bottom, right, level1 - bottom);

        ctx.fillStyle = CHART_LEVEL2_ZONE_COLOR;
        ctx.fillRect(left, level1, right, level2 - level1);

        ctx.fillStyle = CHART_LEVEL3_ZONE_COLOR;
        ctx.fillRect(left, level2, right, level3 - level2);

        ctx.fillStyle = CHART_LEVEL4_ZONE_COLOR;
        ctx.fillRect(left, level3, right, top - level3);

        ctx.restore();
      }
    },
  };

  private verticalLinePlugin: Plugin = {
    id: "2",
    afterDraw: (): void => {
      if (this.chart && this.currentTime) {
        const {
          ctx,
          chartArea: { left, right },
          scales: { x, y },
        } = this.chart;

        var yMin = y.getPixelForValue(y.max);
        var yMax = y.getPixelForValue(y.min);

        var xCurrent = x.getPixelForValue(this.currentTime.getTime());

        // render vertical line
        ctx.beginPath();
        ctx.lineWidth = CHART_CURRENT_TIME_WIDTH;
        ctx.strokeStyle = CHART_CURRENT_TIME_COLOR;
        ctx.moveTo(xCurrent, yMax);
        ctx.lineTo(xCurrent, yMin);
        ctx.stroke();
        // render text
        ctx.textAlign = "center";
        ctx.fillStyle = CHART_CURRENT_TIME_COLOR;
        ctx.fillText("NOW", xCurrent, yMin);
      }
    },
  };

  constructor() {}

  public ngAfterViewInit(): void {
    if (this.data && this.chartContainer) {
      this.renderChart();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      this.data &&
      this.chartContainer &&
      changes.data &&
      JSON.stringify(changes.data.previousValue) !== JSON.stringify(changes.data.currentValue)
    ) {
      this.renderChart();
    }

    if (!this.form) this.form = new FormGroup({ station: new FormControl(null) });

    if (this.selectedStationCode && changes.selectedStationCode) {
      this.form.patchValue({ station: this.selectedStationCode });
    }
  }

  private renderChart(): void {
    const config: ChartConfiguration = this.getChartConfig();

    if (this.chart) this.chart.destroy();
    this.chart = new Chart(this.chartContainer.nativeElement, config);
  }

  private getChartConfig(): ChartConfiguration {
    let suggestedMax =
      this.alertLevels?.level3Centimeters / 100 ||
      this.alertLevels?.level2Centimeters / 100 ||
      this.alertLevels?.level1Centimeters / 100 ||
      this.alertLevels?.level3Discharge ||
      this.alertLevels?.level2Discharge ||
      this.alertLevels?.level1Discharge ||
      this.windSpeedAlertLevels?.level3WindSpeed + 1 ||
      this.windSpeedAlertLevels?.level2WindSpeed ||
      this.windSpeedAlertLevels?.level1WindSpeed ||
      (this.chartType === "line" ? 2 : 10);
    // Append a empy DataPoint for current time. This forces graph to show last 24h.
    let xSuggestedMax = !this.currentTime ? new Date().getTime() : null;

    const data = this.data
      .map((point: DataPointDto) => {
        if (!(point.value || point.value === 0)) {
          return {
            x: new Date(point.timestamp).getTime(),
            y: null,
          };
        } else if (
          !!this.alertLevels?.level3Discharge ||
          !!this.alertLevels?.level2Discharge ||
          !!this.alertLevels?.level1Discharge ||
          !!this.windSpeedAlertLevels?.level1WindSpeed ||
          !!this.windSpeedAlertLevels?.level2WindSpeed ||
          !!this.windSpeedAlertLevels?.level3WindSpeed
        ) {
          return {
            x: new Date(point.timestamp).getTime(),
            y: Math.max(point.value, 0),
          };
        } else {
          return {
            x: new Date(point.timestamp).getTime(),
            y: Math.max(this.chartType === "line" ? point.value / 100 : point.value, 0),
          };
        }
      })
      .reverse();

    // Create windGust chart config
    if (!!this.secondaryData) {
      const windGustData = this.secondaryData
        .map((point: DataPointDto) => {
          if (!(point.value || point.value === 0)) {
            return {
              x: new Date(point.timestamp).getTime(),
              y: null,
            };
          } else if (
            !!this.windSpeedAlertLevels?.level1WindSpeed ||
            !!this.windSpeedAlertLevels?.level2WindSpeed ||
            !!this.windSpeedAlertLevels?.level3WindSpeed
          ) {
            return {
              x: new Date(point.timestamp).getTime(),
              y: Math.max(point.value, 0),
            };
          } else {
            return {
              x: new Date(point.timestamp).getTime(),
              y: Math.max(this.chartType === "line" ? point.value / 100 : point.value, 0),
            };
          }
        })
        .reverse();
      return {
        type: this.chartType,
        data: {
          datasets: [
            {
              data,
              label: this.dataLabel,
              borderColor: primaryLineColor,
              pointBackgroundColor: primaryLineColor,
              backgroundColor: primaryLineColor,
              borderWidth: 1.5,
              pointRadius: 1,
              tension: 0.5,
              minBarLength: 1,
              parsing: false,
            },
            {
              data: windGustData,
              label: this.secondaryDataLabel,
              borderColor: secondaryLineColor,
              pointBackgroundColor: secondaryLineColor,
              backgroundColor: secondaryLineColor,
              borderWidth: 1.5,
              pointRadius: 1,
              tension: 0.5,
              minBarLength: 2,
              parsing: false,
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              labels: {
                boxHeight: 2,
                boxWidth: 24,
              },
            },
          },
          interaction: {
            intersect: false,
            mode: "nearest",
          },
          aspectRatio: 1.5,
          scales: {
            x: {
              type: "time",
              time: {
                unit: "minute",
                tooltipFormat: "YYYY-MM-DD HH:mm",
                displayFormats: {
                  minute: "HH:mm",
                  day: "YYYY-MM-DD HH:mm",
                },
              },
              suggestedMax: xSuggestedMax,
              grid: {
                lineWidth: 1,
                color: gridLinesColor,
              },
              bounds: "data",
              ticks: {
                font,
                color: labelsColor,
                stepSize: 15,
              },
            },
            y: {
              grid: {
                lineWidth: 1,
                color: gridLinesColor,
              },
              min: 0,
              suggestedMax,
              ticks: {
                font,
                color: labelsColor,
                callback:
                  !!this.alertLevels?.level3Centimeters || !!this.alertLevels?.level2Centimeters || !!this.alertLevels?.level1Centimeters
                    ? function (value): string {
                      return value + " m";
                    }
                    : !!this.alertLevels?.level3Discharge || !!this.alertLevels?.level2Discharge || !!this.alertLevels?.level1Discharge
                      ? function (value): string {
                        return value + " m3/s";
                      }
                      : this.varType === WIND_SPEED
                        ? function (value): string {
                          return value + " km/h";
                        }
                        : function (value): string {
                          return value + " mm";
                        },
              },
            },
          },
        },
        plugins: this.chartType === "line" ? [this.drawAlertLevelsPlugin, this.verticalLinePlugin] : null,
      };
    } else {
      return {
        type: this.chartType,
        data: {
          datasets: [
            {
              data,
              label: this.dataLabel,
              borderColor: primaryLineColor,
              pointBackgroundColor: primaryLineColor,
              backgroundColor: primaryLineColor,
              borderWidth: 2,
              pointRadius: 1,
              tension: 0.5,
              minBarLength: 2,
              parsing: false,
            },
          ],
        },
        options: {
          plugins: {
            legend: { display: false },
          },
          interaction: {
            intersect: false,
            mode: "nearest",
          },
          aspectRatio: 1.5,
          scales: {
            x: {
              type: "time",
              time: {
                unit: "minute",
                tooltipFormat: "YYYY-MM-DD HH:mm",
                displayFormats: {
                  minute: "HH:mm",
                  day: "YYYY-MM-DD HH:mm",
                },
              },
              suggestedMax: xSuggestedMax,
              grid: {
                lineWidth: 1,
                color: gridLinesColor,
              },
              bounds: "data",
              ticks: {
                font,
                color: labelsColor,
                stepSize: 15,
              },
            },
            y: {
              grid: {
                lineWidth: 1,
                color: gridLinesColor,
              },
              min: 0,
              suggestedMax,
              ticks: {
                font,
                color: labelsColor,
                callback:
                  !!this.alertLevels?.level3Centimeters || !!this.alertLevels?.level2Centimeters || !!this.alertLevels?.level1Centimeters
                    ? function (value): string {
                      return value + " m";
                    }
                    : !!this.alertLevels?.level3Discharge || !!this.alertLevels?.level2Discharge || !!this.alertLevels?.level1Discharge
                      ? function (value): string {
                        return value + " m3/s";
                      }
                      : this.varType === WIND_SPEED
                        ? function (value): string {
                          return value + " km/h";
                        }
                        : function (value): string {
                          return value + " mm";
                        },
              },
            },
          },
        },
        plugins: this.chartType === "line" ? [this.drawAlertLevelsPlugin, this.verticalLinePlugin] : null,
      };
    }
  }
}
