<div class="layers-manager__popover__content">
    <div class="layers-manager__popover__content__bottom">
        <div class="layers-manager__popover__content__bottom__element --clickable" (click)="selectDataToShow(dataModeVariables.WaterLevel)">
            <div
                class="layers-manager__popover__content__bottom__element__img"
                [class.--selected]="(dataMode$ | async).variable === dataModeVariables.WaterLevel"
            >
                <img class="--small" src="assets/images/water-level.png" />
            </div>
            <p class="--small" [innerHTML]="'LAYERS_MANAGER_COMPONENT.DATA_LAYER.Hidro' | translate"></p>
        </div>
        <div
            class="layers-manager__popover__content__bottom__element --clickable"
            id="hidrometeo_selector_precipitation"
            (click)="selectDataToShow(dataModeVariables.Precipitation)"
        >
            <div
                class="layers-manager__popover__content__bottom__element__img"
                [class.--selected]="(dataMode$ | async).variable === dataModeVariables.Precipitation"
            >
                <img class="--small" src="assets/images/rain.png" />
            </div>
            <p class="--small" [innerHTML]="'LAYERS_MANAGER_COMPONENT.DATA_LAYER.Meteo' | translate"></p>
        </div>
    </div>
</div>
