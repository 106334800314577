import { createReducer, on } from "@ngrx/store";
import { AppState, initialState } from "./state";
import * as AppActions from "./actions";

export const appReducer = createReducer(
  initialState,

  on(AppActions.setModalHeight, (state, { modalHeight }): AppState => ({ ...state, modalHeight })),

  on(AppActions.showLegendModal, (state, { show }): AppState => ({ ...state, showLegendModal: show })),

  on(
    AppActions.switchModalWidth,
    (state): AppState => ({
      ...state,
      modalWidth: state.modalWidth === "450px" ? "900px" : "450px",
    }),
  ),

  on(
    AppActions.refreshState,
    (state): AppState => ({ ...state, refreshCount: 0, refreshState: true, refreshLastDate: new Date().toISOString() }),
  ),

  on(AppActions.setRefreshState, (state, { refreshState }): AppState => ({ ...state, refreshState })),

  on(AppActions.incrementRefreshCounter, (state): AppState => ({ ...state, refreshCount: state.refreshCount + 1 })),
);
