<ion-header>
    <ion-toolbar>
        <ion-buttons slot="end">
            <app-hidrometeo-selector />
            <ion-button [disabled]="true"><ion-icon name="home"></ion-icon></ion-button>
            <ion-button appShareUrl="islands" class="share-button">
                <ion-icon name="share-outline" slot="icon-only"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content appSuppressScrollEvent class="islands">
    <ion-header collapse="condense">
        <ion-toolbar>
            <ion-title class="--h1" [innerHTML]="'ISLANDS_PAGE.TITLE' | translate"></ion-title>
        </ion-toolbar>
    </ion-header>

    <ion-list>
        @switch ((dataMode$ | async).variable) {
            @case (dataModeVariable.WaterLevel) {
                @for (island of islands$ | async | orderByAlertLevel; track island.id) {
                    <app-data-card
                        [description]="'WATER_LEVEL' | translate"
                        [dischargePredicted]="island.dischargePredicted | parseValueStations: dataModeVariable.Discharge"
                        [hovered]="(hoveredIslandId$ | async) === island.id"
                        [selectedDataModeVariable]="(dataMode$ | async).variable"
                        [status]="island.status"
                        [statusPredicted]="island.statusPredicted"
                        [subtitle]="island.name"
                        [title]="island | getValueByDatamode: (dataMode$ | async)"
                        (click)="selectIsland(island.id)"
                        (mouseenter)="onMouseEnter(island.id)"
                        (mouseleave)="onMouseLeave(island.id)"
                    ></app-data-card>
                }
            }
            @case (dataModeVariable.Precipitation) {
                @for (island of islands$ | async | orderByPrecipitation: (dataMode$ | async); track island.id) {
                    <app-data-card
                        [description]="'PRECIPITATION' | translate"
                        [hovered]="(hoveredIslandId$ | async) === island.id"
                        [selectedDataModeVariable]="(dataMode$ | async).variable"
                        [status]=""
                        [subtitle]="island.name"
                        [title]="island | getValueByDatamode: (dataMode$ | async)"
                        (click)="selectIsland(island.id)"
                        (mouseenter)="onMouseEnter(island.id)"
                        (mouseleave)="onMouseLeave(island.id)"
                    ></app-data-card>
                }
            }
        }
    </ion-list>
</ion-content>
