<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<ion-header>
    <ion-toolbar>
        <ion-buttons class="buttons__start" slot="start">
            <ion-back-button icon="arrow-back-outline" [text]="'WATERSHED' | translate" (click)="closeCatchment()"></ion-back-button>
        </ion-buttons>
        <ion-buttons slot="end">
            <app-hidrometeo-selector (click)="scrollToElement(target)" />
            <ion-button (click)="resetToIslandsView()"><ion-icon name="home"></ion-icon></ion-button>
            <ion-button class="catchment__expand" (click)="switchModalWidth()">
                <ion-icon slot="icon-only" [name]="(modalWidth$ | async) === '450px' ? 'expand-outline' : 'contract-outline'"></ion-icon>
            </ion-button>
            <ion-button appShareUrl="catchment">
                <ion-icon name="share-outline" slot="icon-only"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content appSuppressScrollEvent class="catchment">
    <div #target id="resetScroll"></div>
    <ion-header collapse="condense">
        <ion-toolbar>
            <ion-title class="--h1" [innerHTML]="setCatchmentTitle((catchment$ | async)?.name)"></ion-title>
        </ion-toolbar>
    </ion-header>

    <div class="catchment__content">
        <div
            class="catchment__content__description --p1"
            [innerHTML]="
                setCatchmentSubtitleState(catchment$ | async) === 'torrent'
                    ? ('CATCHMENT_PAGE.DESCRIPTION' | translate) + setCatchmentSubtitle(catchment$ | async)
                    : ('CATCHMENT_PAGE.DESCRIPTION_CATCHMENTS' | translate)
            "
        ></div>

        <div class="catchment__content__graphics">
            @switch ((dataMode$ | async).variable) {
                @case (dataModeVariable.Precipitation) {
                    <div class="catchment__content__graphics__graphic">
                        <p class="catchment__content__graphics__graphic__title --h2" [innerHTML]="'PRECIPITATION' | translate"></p>
                        <app-chart
                            chartType="bar"
                            [data]="catchmentPrecipitationData$ | async"
                            [precipitationLast1h]="
                                'PRECIPITATION_UNIT'
                                    | translate
                                        : {
                                              value:
                                                  (catchmentPrecipitationStation$ | async)?.precipitationLast1h
                                                  | number: '1.0-1' : translateService.defaultLang
                                          }
                            "
                            [precipitationLast24h]="
                                'PRECIPITATION_UNIT'
                                    | translate
                                        : {
                                              value:
                                                  (catchmentPrecipitationStation$ | async)?.precipitationLast24h
                                                  | number: '1.0-1' : translateService.defaultLang
                                          }
                            "
                            [selectedStationCode]="selectedPrecipitationStationCode"
                            [stations]="catchmentMeteoStations$ | async"
                            (selectStation)="selectPrecipitationStation($event)"
                        ></app-chart>

                        <p
                            class="catchment__content__graphics__graphic__title --h2"
                            [innerHTML]="'CATCHMENT_PAGE.METEO_STATIONS_TITLE' | translate"
                        ></p>

                        <ion-list>
                            @for (
                                station of catchmentMeteoStations$ | async | orderStationsByPrecipitation: (dataMode$ | async);
                                track station.id
                            ) {
                                @if (station.type === "1") {
                                    <app-station-card
                                        descriptionIcon="assets/images/precipitation.png"
                                        [description]="'PRECIPITATION' | translate"
                                        [hovered]="(hoveredStationCode$ | async) === station.id"
                                        [icon]="
                                            'assets/images/' +
                                            (station.owner === '0' ? 'riscbal' : station.owner === '1' ? 'aemet' : 'wunderground') +
                                            '.png'
                                        "
                                        [items]="station | parseToStationCardItems: 'meteo'"
                                        [subtitle]="
                                            station.lastReadWL
                                                ? ('LAST_UPDATE'
                                                  | translate
                                                      : {
                                                            date: station.lastReadWL | date: 'dd/MM/YY HH:mm' : translateService.defaultLang
                                                        })
                                                : station.lastReadWL
                                        "
                                        [title]="station.id + ' - ' + station.name"
                                        (click)="selectStation(station)"
                                        (mouseenter)="onMouseEnter(station.id)"
                                        (mouseleave)="onMouseLeave(station.id)"
                                    ></app-station-card>
                                }
                                @if (station.type === "0") {
                                    <app-station-card
                                        descriptionIcon="assets/images/precipitation.png"
                                        [description]="'PRECIPITATION' | translate"
                                        [hovered]="(hoveredStationCode$ | async) === station.id"
                                        [icon]="
                                            'assets/images/' +
                                            (station.owner === '0' ? 'riscbal' : station.owner === '1' ? 'aemet' : 'wunderground') +
                                            '.png'
                                        "
                                        [items]="station | parseToStationCardItems: 'meteo'"
                                        [subtitle]="
                                            station.lastReadR60
                                                ? ('LAST_UPDATE'
                                                  | translate
                                                      : {
                                                            date:
                                                                station.lastReadR60 | date: 'dd/MM/YY HH:mm' : translateService.defaultLang
                                                        })
                                                : station.lastReadR60
                                        "
                                        [title]="station.id + ' - ' + station.name"
                                        (click)="selectStation(station)"
                                        (mouseenter)="onMouseEnter(station.id)"
                                        (mouseleave)="onMouseLeave(station.id)"
                                    ></app-station-card>
                                }
                                @if (station.type === "2") {
                                    <app-station-card
                                        descriptionIcon="assets/images/precipitation.png"
                                        [description]="'PRECIPITATION' | translate"
                                        [hovered]="(hoveredStationCode$ | async) === station.id"
                                        [icon]="
                                            'assets/images/' +
                                            (station.owner === '0' ? 'riscbal' : station.owner === '1' ? 'aemet' : 'wunderground') +
                                            '.png'
                                        "
                                        [items]="station | parseToStationCardItems: 'meteo'"
                                        [subtitle]="
                                            station.lastReadR60
                                                ? ('LAST_UPDATE'
                                                  | translate
                                                      : {
                                                            date:
                                                                station.lastReadR60 | date: 'dd/MM/YY HH:mm' : translateService.defaultLang
                                                        })
                                                : station.lastReadR60
                                        "
                                        [title]="station.id + ' - ' + station.name"
                                        (click)="selectStation(station)"
                                        (mouseenter)="onMouseEnter(station.id)"
                                        (mouseleave)="onMouseLeave(station.id)"
                                    ></app-station-card>
                                }
                            }
                        </ion-list>
                    </div>
                    <div class="catchment__content__graphics__graphic">
                        <p class="catchment__content__graphics__graphic__title --h2" [innerHTML]="'WATER_LEVEL' | translate"></p>

                        <app-chart
                            chartType="line"
                            [alertLevels]="(catchmentWaterLevelStationData$ | async)?.alertLevels"
                            [data]="(catchmentWaterLevelStationData$ | async)?.waterLevel"
                            [selectedStationCode]="selectedWaterLevelStationCode"
                            [stations]="catchmentHydroStations$ | async"
                            (selectStation)="selectWaterLevelStation($event)"
                        ></app-chart>

                        <p
                            class="catchment__content__graphics__graphic__title --h2"
                            [innerHTML]="'CATCHMENT_PAGE.HYDRO_STATIONS_TITLE' | translate"
                        ></p>

                        <ion-list>
                            @for (station of catchmentHydroStations$ | async | orderStationsByWaterLevel; track station.id) {
                                @if (station.type === "1") {
                                    <app-station-card
                                        descriptionIcon="assets/images/water.png"
                                        [description]="'WATER_LEVEL' | translate"
                                        [hovered]="(hoveredStationCode$ | async) === station.id"
                                        [icon]="
                                            'assets/images/' +
                                            (station.owner === '0' ? 'riscbal' : station.owner === '1' ? 'aemet' : 'wunderground') +
                                            '.png'
                                        "
                                        [items]="station | parseToStationCardItems: 'hydro'"
                                        [status]="station.status"
                                        [subtitle]="
                                            station.lastReadWL
                                                ? ('LAST_UPDATE'
                                                  | translate
                                                      : {
                                                            date: station.lastReadWL | date: 'dd/MM/YY HH:mm' : translateService.defaultLang
                                                        })
                                                : station.lastReadWL
                                        "
                                        [title]="station.id + ' - ' + station.name"
                                        (click)="selectStation(station)"
                                        (mouseenter)="onMouseEnter(station.id)"
                                        (mouseleave)="onMouseLeave(station.id)"
                                    ></app-station-card>
                                }
                                @if (station.type === "0") {
                                    <app-station-card
                                        descriptionIcon="assets/images/water.png"
                                        [description]="'WATER_LEVEL' | translate"
                                        [hovered]="(hoveredStationCode$ | async) === station.id"
                                        [icon]="
                                            'assets/images/' +
                                            (station.owner === '0' ? 'riscbal' : station.owner === '1' ? 'aemet' : 'wunderground') +
                                            '.png'
                                        "
                                        [items]="station | parseToStationCardItems: 'hydro'"
                                        [status]="station.status"
                                        [subtitle]="
                                            station.lastReadR60
                                                ? ('LAST_UPDATE'
                                                  | translate
                                                      : {
                                                            date:
                                                                station.lastReadR60 | date: 'dd/MM/YY HH:mm' : translateService.defaultLang
                                                        })
                                                : station.lastReadR60
                                        "
                                        [title]="station.id + ' - ' + station.name"
                                        (click)="selectStation(station)"
                                        (mouseenter)="onMouseEnter(station.id)"
                                        (mouseleave)="onMouseLeave(station.id)"
                                    ></app-station-card>
                                }
                                @if (station.type === "2") {
                                    <app-station-card
                                        descriptionIcon="assets/images/water.png"
                                        [description]="'WATER_LEVEL' | translate"
                                        [hovered]="(hoveredStationCode$ | async) === station.id"
                                        [icon]="
                                            'assets/images/' +
                                            (station.owner === '0' ? 'riscbal' : station.owner === '1' ? 'aemet' : 'wunderground') +
                                            '.png'
                                        "
                                        [items]="station | parseToStationCardItems: 'hydro'"
                                        [status]="station.status"
                                        [subtitle]="
                                            station.lastReadWL
                                                ? ('LAST_UPDATE'
                                                  | translate
                                                      : {
                                                            date: station.lastReadWL | date: 'dd/MM/YY HH:mm' : translateService.defaultLang
                                                        })
                                                : station.lastReadWL
                                        "
                                        [title]="station.id + ' - ' + station.name"
                                        (click)="selectStation(station)"
                                        (mouseenter)="onMouseEnter(station.id)"
                                        (mouseleave)="onMouseLeave(station.id)"
                                    ></app-station-card>
                                }
                            }
                        </ion-list>
                    </div>
                }
                @case (dataModeVariable.WaterLevel) {
                    <div class="catchment__content__graphics__graphic">
                        <p class="catchment__content__graphics__graphic__title --h2" [innerHTML]="'WATER_LEVEL' | translate"></p>

                        <app-chart
                            chartType="line"
                            [alertLevels]="(catchmentWaterLevelStationData$ | async)?.alertLevels"
                            [data]="(catchmentWaterLevelStationData$ | async)?.waterLevel"
                            [selectedStationCode]="selectedWaterLevelStationCode"
                            [stations]="catchmentHydroStations$ | async"
                            (selectStation)="selectWaterLevelStation($event)"
                        ></app-chart>

                        <p
                            class="catchment__content__graphics__graphic__title --h2"
                            [innerHTML]="'CATCHMENT_PAGE.HYDRO_STATIONS_TITLE' | translate"
                        ></p>

                        <ion-list>
                            @for (station of catchmentHydroStations$ | async | orderStationsByWaterLevel; track station.id) {
                                @if (station.type === "1") {
                                    <app-station-card
                                        descriptionIcon="assets/images/water.png"
                                        [description]="'WATER_LEVEL' | translate"
                                        [hovered]="(hoveredStationCode$ | async) === station.id"
                                        [icon]="
                                            'assets/images/' +
                                            (station.owner === '0' ? 'riscbal' : station.owner === '1' ? 'aemet' : 'wunderground') +
                                            '.png'
                                        "
                                        [items]="station | parseToStationCardItems: 'hydro'"
                                        [status]="station.status"
                                        [subtitle]="
                                            station.lastReadWL
                                                ? ('LAST_UPDATE'
                                                  | translate
                                                      : {
                                                            date: station.lastReadWL | date: 'dd/MM/YY HH:mm' : translateService.defaultLang
                                                        })
                                                : station.lastReadWL
                                        "
                                        [title]="station.id + ' - ' + station.name"
                                        (click)="selectStation(station)"
                                        (mouseenter)="onMouseEnter(station.id)"
                                        (mouseleave)="onMouseLeave(station.id)"
                                    ></app-station-card>
                                }
                                @if (station.type === "0") {
                                    <app-station-card
                                        descriptionIcon="assets/images/water.png"
                                        [description]="'WATER_LEVEL' | translate"
                                        [hovered]="(hoveredStationCode$ | async) === station.id"
                                        [icon]="
                                            'assets/images/' +
                                            (station.owner === '0' ? 'riscbal' : station.owner === '1' ? 'aemet' : 'wunderground') +
                                            '.png'
                                        "
                                        [items]="station | parseToStationCardItems: 'hydro'"
                                        [status]="station.status"
                                        [subtitle]="
                                            station.lastReadR60
                                                ? ('LAST_UPDATE'
                                                  | translate
                                                      : {
                                                            date:
                                                                station.lastReadR60 | date: 'dd/MM/YY HH:mm' : translateService.defaultLang
                                                        })
                                                : station.lastReadR60
                                        "
                                        [title]="station.id + ' - ' + station.name"
                                        (click)="selectStation(station)"
                                        (mouseenter)="onMouseEnter(station.id)"
                                        (mouseleave)="onMouseLeave(station.id)"
                                    ></app-station-card>
                                }
                                @if (station.type === "2") {
                                    <app-station-card
                                        descriptionIcon="assets/images/water.png"
                                        [description]="'WATER_LEVEL' | translate"
                                        [hovered]="(hoveredStationCode$ | async) === station.id"
                                        [icon]="
                                            'assets/images/' +
                                            (station.owner === '0' ? 'riscbal' : station.owner === '1' ? 'aemet' : 'wunderground') +
                                            '.png'
                                        "
                                        [items]="station | parseToStationCardItems: 'hydro'"
                                        [status]="station.status"
                                        [subtitle]="
                                            station.lastReadWL
                                                ? ('LAST_UPDATE'
                                                  | translate
                                                      : {
                                                            date: station.lastReadWL | date: 'dd/MM/YY HH:mm' : translateService.defaultLang
                                                        })
                                                : station.lastReadWL
                                        "
                                        [title]="station.id + ' - ' + station.name"
                                        (click)="selectStation(station)"
                                        (mouseenter)="onMouseEnter(station.id)"
                                        (mouseleave)="onMouseLeave(station.id)"
                                    ></app-station-card>
                                }
                            }
                        </ion-list>
                    </div>
                    <div class="catchment__content__graphics__graphic">
                        <p class="catchment__content__graphics__graphic__title --h2" [innerHTML]="'PRECIPITATION' | translate"></p>

                        <app-chart
                            chartType="bar"
                            [data]="catchmentPrecipitationData$ | async"
                            [precipitationLast1h]="
                                'PRECIPITATION_UNIT'
                                    | translate
                                        : {
                                              value:
                                                  (catchmentPrecipitationStation$ | async)?.precipitationLast1h
                                                  | number: '1.0-1' : translateService.defaultLang
                                          }
                            "
                            [precipitationLast24h]="
                                'PRECIPITATION_UNIT'
                                    | translate
                                        : {
                                              value:
                                                  (catchmentPrecipitationStation$ | async)?.precipitationLast24h
                                                  | number: '1.0-1' : translateService.defaultLang
                                          }
                            "
                            [selectedStationCode]="selectedPrecipitationStationCode"
                            [stations]="catchmentMeteoStations$ | async"
                            (selectStation)="selectPrecipitationStation($event)"
                        ></app-chart>

                        <p
                            class="catchment__content__graphics__graphic__title --h2"
                            [innerHTML]="'CATCHMENT_PAGE.METEO_STATIONS_TITLE' | translate"
                        ></p>

                        <ion-list>
                            @for (
                                station of catchmentMeteoStations$ | async | orderStationsByPrecipitation: (dataMode$ | async);
                                track station.id
                            ) {
                                @if (station.type === "1") {
                                    <app-station-card
                                        descriptionIcon="assets/images/precipitation.png"
                                        [description]="'PRECIPITATION' | translate"
                                        [hovered]="(hoveredStationCode$ | async) === station.id"
                                        [icon]="
                                            'assets/images/' +
                                            (station.owner === '0' ? 'riscbal' : station.owner === '1' ? 'aemet' : 'wunderground') +
                                            '.png'
                                        "
                                        [items]="station | parseToStationCardItems: 'meteo'"
                                        [subtitle]="
                                            station.lastReadWL
                                                ? ('LAST_UPDATE'
                                                  | translate
                                                      : {
                                                            date: station.lastReadWL | date: 'dd/MM/YY HH:mm' : translateService.defaultLang
                                                        })
                                                : station.lastReadWL
                                        "
                                        [title]="station.id + ' - ' + station.name"
                                        (click)="selectStation(station)"
                                        (mouseenter)="onMouseEnter(station.id)"
                                        (mouseleave)="onMouseLeave(station.id)"
                                    ></app-station-card>
                                }
                                @if (station.type === "0") {
                                    <app-station-card
                                        descriptionIcon="assets/images/precipitation.png"
                                        [description]="'PRECIPITATION' | translate"
                                        [hovered]="(hoveredStationCode$ | async) === station.id"
                                        [icon]="
                                            'assets/images/' +
                                            (station.owner === '0' ? 'riscbal' : station.owner === '1' ? 'aemet' : 'wunderground') +
                                            '.png'
                                        "
                                        [items]="station | parseToStationCardItems: 'meteo'"
                                        [subtitle]="
                                            station.lastReadR60
                                                ? ('LAST_UPDATE'
                                                  | translate
                                                      : {
                                                            date:
                                                                station.lastReadR60 | date: 'dd/MM/YY HH:mm' : translateService.defaultLang
                                                        })
                                                : station.lastReadR60
                                        "
                                        [title]="station.id + ' - ' + station.name"
                                        (click)="selectStation(station)"
                                        (mouseenter)="onMouseEnter(station.id)"
                                        (mouseleave)="onMouseLeave(station.id)"
                                    ></app-station-card>
                                }
                                @if (station.type === "2") {
                                    <app-station-card
                                        descriptionIcon="assets/images/precipitation.png"
                                        [description]="'PRECIPITATION' | translate"
                                        [hovered]="(hoveredStationCode$ | async) === station.id"
                                        [icon]="
                                            'assets/images/' +
                                            (station.owner === '0' ? 'riscbal' : station.owner === '1' ? 'aemet' : 'wunderground') +
                                            '.png'
                                        "
                                        [items]="station | parseToStationCardItems: 'meteo'"
                                        [subtitle]="
                                            station.lastReadR60
                                                ? ('LAST_UPDATE'
                                                  | translate
                                                      : {
                                                            date:
                                                                station.lastReadR60 | date: 'dd/MM/YY HH:mm' : translateService.defaultLang
                                                        })
                                                : station.lastReadR60
                                        "
                                        [title]="station.id + ' - ' + station.name"
                                        (click)="selectStation(station)"
                                        (mouseenter)="onMouseEnter(station.id)"
                                        (mouseleave)="onMouseLeave(station.id)"
                                    ></app-station-card>
                                }
                            }
                        </ion-list>
                    </div>
                }
            }
        </div>
    </div>
</ion-content>
