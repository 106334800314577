import { ModalWidth } from "src/app/types";

export interface AppState {
  refreshState: boolean;
  refreshCount: number;
  refreshLastDate: string;
  modalHeight: number;
  modalWidth: ModalWidth;
  showLegendModal: boolean;
}

export const initialState: AppState = {
  refreshState: false,
  refreshCount: 0,
  refreshLastDate: null,
  modalHeight: 0,
  modalWidth: "450px",
  showLegendModal: false,
};

export const persistedKeys: (keyof AppState)[] = [];
