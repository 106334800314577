<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<ion-header>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-back-button
                icon="arrow-back-outline"
                [text]="'WATERSHED_PAGE.TITLE' | translate"
                (click)="closeWatershed()"
            ></ion-back-button>
        </ion-buttons>
        <ion-buttons slot="end">
            <app-hidrometeo-selector />
            <ion-button (click)="resetToIslandsView()"><ion-icon name="home"></ion-icon></ion-button>
            <ion-button appShareUrl="watershed">
                <ion-icon name="share-outline" slot="icon-only"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content appSuppressScrollEvent class="watershed">
    <ion-header collapse="condense">
        <ion-toolbar>
            <ion-title class="--h1" [innerHTML]="(watershed$ | async)?.name"></ion-title>
        </ion-toolbar>
    </ion-header>

    <div
        class="watershed__description ion-padding --p1"
        [innerHTML]="'WATERSHED_PAGE.DESCRIPTION' | translate: { watershed: (watershed$ | async)?.name }"
    ></div>

    <ion-list>
        @switch ((dataMode$ | async).variable) {
            @case (dataModeVariable.WaterLevel) {
                @for (watershedCatchment of watershedCatchments$ | async | orderByAlertLevel; track watershedCatchment.id) {
                    <app-data-card
                        [description]="'WATER_LEVEL' | translate"
                        [dischargePredicted]="watershedCatchment.dischargePredicted | parseValueStations: dataModeVariable.Discharge"
                        [hovered]="(hoveredCatchmentId$ | async) === watershedCatchment.id"
                        [selectedDataModeVariable]="(dataMode$ | async).variable"
                        [status]="watershedCatchment.status"
                        [statusPredicted]="watershedCatchment.statusPredicted"
                        [subtitle]="setCatchmentTitle(watershedCatchment)"
                        [title]="watershedCatchment | getValueByDatamode: (dataMode$ | async)"
                        (click)="selectCatchment(watershedCatchment.id)"
                        (mouseenter)="onMouseEnter(watershedCatchment.id)"
                        (mouseleave)="onMouseLeave(watershedCatchment.id)"
                    ></app-data-card>
                }
            }
            @case (dataModeVariable.Precipitation) {
                @for (
                    watershedCatchment of watershedCatchments$ | async | orderByPrecipitation: (dataMode$ | async);
                    track watershedCatchment.id
                ) {
                    <app-data-card
                        [description]="'PRECIPITATION' | translate"
                        [hovered]="(hoveredCatchmentId$ | async) === watershedCatchment.id"
                        [selectedDataModeVariable]="(dataMode$ | async).variable"
                        [status]=""
                        [subtitle]="setCatchmentTitle(watershedCatchment)"
                        [title]="watershedCatchment | getValueByDatamode: (dataMode$ | async)"
                        (click)="selectCatchment(watershedCatchment.id)"
                        (mouseenter)="onMouseEnter(watershedCatchment.id)"
                        (mouseleave)="onMouseLeave(watershedCatchment.id)"
                    ></app-data-card>
                }
            }
        }
    </ion-list>
</ion-content>
