/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject, combineLatest, filter, first, pairwise, takeUntil, tap, withLatestFrom } from "rxjs";
import { CatchmentDto, StationDataDto, StationDto, WatershedDto, DataPointDto } from "src/app/services/api";
import { RootState } from "src/app/store";
import * as WatershedActions from "src/app/store/watershed/actions";
import * as FromWatershed from "src/app/store/watershed/selectors";
import * as AppActions from "src/app/store/app/actions";
import * as FromApp from "src/app/store/app/selectors";
import { DOCUMENT } from "@angular/common";
import { ModalWidth } from "src/app/types";
import { BaseMapLayerConfig, DataMode } from "src/app/interfaces";
import { DataModeVariable } from "src/app/enums";
import { ScrollService } from "src/app/services/scroll/sroll.service";
import { GROUPPED_CATCHMENTS } from "src/app/constants";

@Component({
  selector: "app-catchment",
  templateUrl: "./catchment.component.html",
  styleUrls: ["./catchment.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatchmentComponent {
  public dataModeVariable: typeof DataModeVariable = DataModeVariable;

  public watershedText: string = "Vessant";

  public selectedWaterLevelStationCode: string;
  public selectedPrecipitationStationCode: string;

  public readonly baseMapLayerConfig$: Observable<BaseMapLayerConfig> = this.store.select(FromWatershed.selectMapLayerConfig);

  public readonly refreshState$: Observable<boolean> = this.store.select(FromApp.selectRefreshState);

  public readonly modalWidth$: Observable<ModalWidth> = this.store.select(FromApp.selectModalWidth);

  public readonly dataMode$: Observable<DataMode> = this.store.select(FromWatershed.selectDataMode);

  public readonly watershedId$: Observable<number> = this.store.select(FromWatershed.selectWatershedId);
  public readonly catchmentId$: Observable<number> = this.store.select(FromWatershed.selectCatchmentId);
  public readonly islandId$: Observable<string> = this.store.select(FromWatershed.selectIslandId);
  public readonly watershed$: Observable<WatershedDto> = this.store.select(FromWatershed.selectWatershed);

  public readonly hoveredStationCode$: Observable<string> = this.store.select(FromWatershed.selectHoveredStationCode);

  public readonly catchment$: Observable<CatchmentDto> = this.store.select(FromWatershed.selectIslandCatchment);

  public readonly catchmentWaterLevelStationCode$: Observable<string> = this.store.select(
    FromWatershed.selectCatchmentWaterLevelStationCode,
  );

  public readonly catchmentWaterLevelStationData$: Observable<StationDataDto> = this.store.select(
    FromWatershed.selectCatchmentWaterLevelStationData,
  );

  public readonly catchmentPrecipitationStationCode$: Observable<string> = this.store.select(
    FromWatershed.selectCatchmentPrecipitationStationCode,
  );

  public readonly catchmentPrecipitationData$: Observable<DataPointDto[]> = this.store.select(
    FromWatershed.selectCatchmentPrecipitationData,
  );

  public readonly catchmentPrecipitationStation$: Observable<StationDto> = this.store.select(FromWatershed.catchmentPrecipitationStation);

  public readonly catchmentHydroStations$: Observable<StationDto[]> = this.store.select(
    FromWatershed.selectWatershedCatchmentHydroStations,
  );

  public readonly catchmentMeteoStations$: Observable<StationDto[]> = this.store.select(
    FromWatershed.selectWatershedCatchmentMeteoStations,
  );

  private unsubscribeSubject$: Subject<void>;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly store: Store<RootState>,
    public readonly translateService: TranslateService,
    private readonly scrollService: ScrollService,
  ) {}

  public ionViewWillEnter(): void {
    this.unsubscribeSubject$ = new Subject();

    this.selectWaterLevelStation(null);
    this.selectPrecipitationStation(null);
    this.catchmentHydroStations$
      .pipe(
        first(stations => !!stations?.length),
        withLatestFrom(this.catchmentWaterLevelStationCode$),
        tap(([stations, catchmentWaterLevelStationCode]) => {
          if (!catchmentWaterLevelStationCode) {
            const maxWaterLevelStation = stations.reduce((acum, station) => {
              if (!acum || acum.waterLevel < station.waterLevel) {
                acum = station;
                return acum;
              }
              return acum;
            }, null);
            if (maxWaterLevelStation) this.selectWaterLevelStation(maxWaterLevelStation.id);
          } else {
            this.selectWaterLevelStation(catchmentWaterLevelStationCode);
          }
        }),
      )
      .subscribe();

    this.catchmentMeteoStations$
      .pipe(
        first(stations => !!stations?.length),
        withLatestFrom(this.catchmentPrecipitationStationCode$),
        tap(([stations, catchmentPrecipitationStationCode]) => {
          if (!catchmentPrecipitationStationCode) {
            const maxPrecipitationStation = stations.reduce((acum, station) => {
              if (!acum || acum.precipitationLast1h < station.precipitationLast1h) {
                acum = station;
                return acum;
              }
              return acum;
            }, null);
            if (maxPrecipitationStation) this.selectPrecipitationStation(maxPrecipitationStation.id);
          } else {
            this.selectPrecipitationStation(catchmentPrecipitationStationCode);
          }
        }),
      )
      .subscribe();
    combineLatest([this.catchmentWaterLevelStationCode$, this.catchmentPrecipitationStationCode$])
      .pipe(
        takeUntil(this.unsubscribeSubject$),
        pairwise(),
        tap(
          ([
            [previousCatchmentWaterLevelStationCode, previousCatchmentPrecipitationStationCode],
            [catchmentWaterLevelStationCode, catchmentPrecipitationStationCode],
          ]) => {
            if (previousCatchmentWaterLevelStationCode !== catchmentWaterLevelStationCode && catchmentWaterLevelStationCode) {
              this.store.dispatch(WatershedActions.loadStationData({ stationCode: catchmentWaterLevelStationCode }));
            }

            if (previousCatchmentPrecipitationStationCode !== catchmentPrecipitationStationCode && catchmentPrecipitationStationCode) {
              this.store.dispatch(WatershedActions.loadStationData({ stationCode: catchmentPrecipitationStationCode }));
            }
          },
        ),
      )
      .subscribe();

    this.islandId$
      .pipe(
        takeUntil(this.unsubscribeSubject$),
        filter(islandId => !!islandId),
        tap(islandId => {
          this.store.dispatch(WatershedActions.loadAllCatchments({ islandId }));
        }),
      )
      .subscribe();

    this.catchmentId$
      .pipe(
        takeUntil(this.unsubscribeSubject$),
        filter(catchmentId => !!catchmentId),
        tap(catchmentId => {
          this.loadCatchment(catchmentId);
        }),
      )
      .subscribe();

    this.refreshState$
      .pipe(
        takeUntil(this.unsubscribeSubject$),
        filter(refreshState => !!refreshState),
        withLatestFrom(this.catchmentId$, this.catchmentWaterLevelStationCode$, this.catchmentPrecipitationStationCode$),
        filter(([, catchmentId]) => !!catchmentId),
        tap(([, catchmentId, catchmentWaterLevelStationCode, catchmentPrecipitationStationCode]) => {
          this.loadCatchment(catchmentId);

          if (catchmentWaterLevelStationCode) {
            this.store.dispatch(WatershedActions.loadStationData({ stationCode: catchmentWaterLevelStationCode }));
          }

          if (catchmentPrecipitationStationCode) {
            this.store.dispatch(WatershedActions.loadStationData({ stationCode: catchmentPrecipitationStationCode }));
          }
        }),
      )
      .subscribe();

    this.modalWidth$
      .pipe(
        first(),
        filter(modalWidth => modalWidth === "900px"),
        tap(modalWidth => this.document.documentElement.style.setProperty("--modal-width", modalWidth)),
      )
      .subscribe();
  }

  public ionViewWillLeave(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  public scrollToElement(element: HTMLElement): void {
    this.scrollService.scrollToElement(element);
  }

  public resetToIslandsView(): void {
    this.store.dispatch(WatershedActions.setCatchmentId({ catchmentId: null }));
    this.store.dispatch(WatershedActions.setWatershedId({ watershedId: null }));
    this.store.dispatch(WatershedActions.setIslandId({ islandId: null }));
    this.store.dispatch(WatershedActions.resetToIslandsView());
  }

  public async closeCatchment(): Promise<void> {
    this.modalWidth$
      .pipe(
        first(),
        filter(modalWidth => modalWidth === "900px"),
        tap(() => this.store.dispatch(AppActions.switchModalWidth())),
      )
      .subscribe();
    this.store.dispatch(WatershedActions.setCatchmentId({ catchmentId: null }));
  }

  public selectStation(station: StationDto): void {
    this.modalWidth$
      .pipe(
        first(),
        filter(modalWidth => modalWidth === "900px"),
        tap(() => this.store.dispatch(AppActions.switchModalWidth())),
      )
      .subscribe();

    this.store.dispatch(WatershedActions.setStationCode({ stationCode: station.id }));
  }

  public onMouseEnter(stationCode: string): void {
    this.store.dispatch(WatershedActions.setHoveredStationCode({ stationCode, hoveredStationCode: stationCode }));
  }

  public onMouseLeave(stationCode: string): void {
    this.store.dispatch(WatershedActions.setHoveredStationCode({ stationCode, hoveredStationCode: null }));
  }

  public selectWaterLevelStation(catchmentWaterLevelStationCode: string): void {
    this.selectedWaterLevelStationCode = catchmentWaterLevelStationCode;
    this.store.dispatch(WatershedActions.setCatchmentWaterLevelStationCode({ catchmentWaterLevelStationCode }));
  }

  public selectPrecipitationStation(catchmentPrecipitationStationCode: string): void {
    this.selectedPrecipitationStationCode = catchmentPrecipitationStationCode;
    this.store.dispatch(WatershedActions.setCatchmentPrecipitationStationCode({ catchmentPrecipitationStationCode }));
  }

  public async switchModalWidth(): Promise<void> {
    this.store.dispatch(AppActions.switchModalWidth());
  }

  public setCatchmentTitle(catchment: string): string {
    let catchmentText: string = "";
    if (catchment) {
      if (catchment === "Gros" || catchment === "Major") {
        catchmentText = "Torrent " + catchment;
      } else if (
        catchment.charAt(0) === "A" ||
        catchment.charAt(0) === "E" ||
        catchment.charAt(0) === "I" ||
        catchment.charAt(0) === "O" ||
        catchment.charAt(0) === "U"
      ) {
        catchmentText = "Torrent d'" + catchment;
      } else {
        catchmentText = "Torrent de " + catchment;
      }
      let currentCatchmentId: number;
      this.catchmentId$.subscribe(catchmentId => (currentCatchmentId = catchmentId));
      GROUPPED_CATCHMENTS.forEach(catchmentId => {
        if (catchmentId === currentCatchmentId) catchmentText = catchment;
      });
    }

    return catchmentText;
  }

  public setCatchmentSubtitle(catchment: CatchmentDto): string {
    let catchmentText: string;

    if (catchment.id === 11 || catchment.id === 49) {
      catchmentText = catchment.name;
    } else if (
      catchment.name.charAt(0) === "A" ||
      catchment.name.charAt(0) === "E" ||
      catchment.name.charAt(0) === "I" ||
      catchment.name.charAt(0) === "O" ||
      catchment.name.charAt(0) === "U"
    ) {
      catchmentText = "d'" + catchment.name;
    } else {
      catchmentText = "de " + catchment.name;
    }

    GROUPPED_CATCHMENTS.forEach(catchmentId => {
      if (catchmentId === catchment.id) catchmentText = "a aquestes conques";
    });
    return catchmentText;
  }

  public setCatchmentSubtitleState(catchment: CatchmentDto): string {
    let catchmentState: string;

    if (catchment) {
      if (
        catchment.id === 11 ||
        catchment.id === 49 ||
        catchment.name.charAt(0) === "A" ||
        catchment.name.charAt(0) === "E" ||
        catchment.name.charAt(0) === "I" ||
        catchment.name.charAt(0) === "O" ||
        catchment.name.charAt(0) === "U"
      ) {
        catchmentState = "torrent";
      } else {
        catchmentState = "torrent";
      }

      GROUPPED_CATCHMENTS.forEach(catchmentId => {
        if (catchmentId === catchment.id) catchmentState = "conques";
      });
    }
    return catchmentState;
  }

  private loadCatchment(catchmentId: number): void {
    this.store.dispatch(WatershedActions.loadAnyCatchmentStations({ catchmentId: catchmentId }));
    this.store.dispatch(WatershedActions.loadAnyCatchmentBlackspots({ catchmentId: catchmentId }));
  }
}
