import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GetValueByDatamodePipe } from "./get-value-by-datamode.pipe";

@NgModule({
  declarations: [GetValueByDatamodePipe],
  imports: [CommonModule],
  exports: [GetValueByDatamodePipe],
})
export class GetValueByDatamodePipeModule {}
