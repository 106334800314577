import { ChangeDetectionStrategy, Component } from "@angular/core";
import * as WatershedActions from "src/app/store/watershed/actions";
import * as FromWatershed from "src/app/store/watershed/selectors";
import * as FromApp from "src/app/store/app/selectors";
import { Store } from "@ngrx/store";
import { RootState } from "src/app/store";
import { Observable, Subject, filter, takeUntil, tap, withLatestFrom } from "rxjs";
import { IslandDto, WatershedDto } from "src/app/services/api";
import { TranslateService } from "@ngx-translate/core";
import { DataModeVariable, IslandsNames } from "src/app/enums";
import { DataMode } from "src/app/interfaces";

@Component({
  selector: "app-watersheds",
  templateUrl: "./watersheds.component.html",
  styleUrls: ["./watersheds.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WatershedsComponent {
  public DataModeVariable: typeof DataModeVariable = DataModeVariable;

  public readonly dataMode$: Observable<DataMode> = this.store.select(FromWatershed.selectDataMode);
  public readonly islandId$: Observable<string> = this.store.select(FromWatershed.selectIslandId);
  public readonly island$: Observable<IslandDto> = this.store.select(FromWatershed.selectIsland);
  public readonly hoveredWatershedId$: Observable<number> = this.store.select(FromWatershed.selectHoveredWatershedId);
  public readonly watersheds$: Observable<WatershedDto[]> = this.store.select(FromWatershed.selectWatersheds);

  public readonly refreshState$: Observable<boolean> = this.store.select(FromApp.selectRefreshState);

  private unsubscribeSubject$: Subject<void>;

  constructor(
    private readonly store: Store<RootState>,
    public readonly translateService: TranslateService,
  ) {}

  public ionViewWillEnter(): void {
    this.unsubscribeSubject$ = new Subject();

    this.islandId$
      .pipe(
        takeUntil(this.unsubscribeSubject$),
        filter(islandId => !!islandId),
        tap(islandId => {
          this.store.dispatch(WatershedActions.loadWatersheds({ islandId }));
        }),
      )
      .subscribe();

    this.refreshState$
      .pipe(
        takeUntil(this.unsubscribeSubject$),
        filter(refreshState => !!refreshState),
        withLatestFrom(this.islandId$),
        filter(([, islandId]) => !!islandId),
        tap(([, islandId]) => {
          this.store.dispatch(WatershedActions.loadWatersheds({ islandId }));
        }),
      )
      .subscribe();
  }

  public ionViewWillLeave(): void {
    this.unsubscribeSubject$.next();
    this.unsubscribeSubject$.complete();
  }

  public isPitiuses(island: IslandDto): boolean {
    if (island && island?.name === IslandsNames.Pitiuses) {
      return true;
    } else {
      return false;
    }
  }

  public resetToIslandsView(): void {
    this.store.dispatch(WatershedActions.setIslandId({ islandId: null }));
    this.store.dispatch(WatershedActions.resetToIslandsView());
  }

  public closeIsland(): void {
    this.store.dispatch(WatershedActions.setIslandId({ islandId: null }));
  }

  public selectWatershed(watershedId: string | number): void {
    this.store.dispatch(WatershedActions.setWatershedId({ watershedId: +watershedId }));
  }

  public onMouseEnter(watershedId: string | number): void {
    this.store.dispatch(WatershedActions.setHoveredWatershedId({ watershedId: +watershedId, hoveredWatershedId: +watershedId }));
  }

  public onMouseLeave(watershedId: string | number): void {
    this.store.dispatch(WatershedActions.setHoveredWatershedId({ watershedId: +watershedId, hoveredWatershedId: null }));
  }
}
