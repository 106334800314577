import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { DataModeType, DataModeVariable } from "src/app/enums";
import { DataMode } from "src/app/interfaces";

@Component({
  selector: "app-data-card",
  templateUrl: "./data-card.component.html",
  styleUrls: ["./data-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataCardComponent {
  @Input() public title: string;
  @Input() public subtitle: string;
  @Input() public description: string;
  @Input() public hovered: boolean;
  @Input() public status?: number;
  @Input() public statusPredicted?: number;
  @Input() public discharge?: string;
  @Input() public dischargePredicted?: string;
  @Input() public selectedDataModeVariable?: DataModeVariable;

  public dataModeVariable: typeof DataModeVariable = DataModeVariable;
}
