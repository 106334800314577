import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LegendComponent } from "./legend.component";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [LegendComponent],
  imports: [CommonModule, IonicModule, TranslateModule],
})
export class LegendModule {}
