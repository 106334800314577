import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as FromWatershed from "src/app/store/watershed/selectors";
import * as WatershedActions from "src/app/store/watershed/actions";
import { Observable } from "rxjs";
import { DataModeVariable } from "src/app/enums";
import { RootState } from "src/app/store";
import { DataMode } from "src/app/interfaces";
import { MapLayersWMSTime } from "src/app/types";

@Component({
  selector: "app-hidrometeo-selector",
  templateUrl: "./hidrometeo-selector.component.html",
  styleUrls: ["./hidrometeo-selector.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HidroMeteoSelectorComponent {
  public dataModeVariables: typeof DataModeVariable = DataModeVariable;
  public readonly dataMode$: Observable<DataMode> = this.store.select(FromWatershed.selectDataMode);
  public readonly mapLayerTime$: Observable<MapLayersWMSTime> = this.store.select(FromWatershed.selectMapLayerWMSTime);
  public currentMapLayerTime: MapLayersWMSTime;

  constructor(
    private readonly store: Store<RootState>,
    public readonly translateService: TranslateService,
  ) {
    this.mapLayerTime$.subscribe(mapLayerTime => (this.currentMapLayerTime = mapLayerTime));
  }

  public selectDataToShow(dataModeVariable: DataModeVariable): void {
    switch (dataModeVariable) {
      case DataModeVariable.Discharge:
        this.store.dispatch(WatershedActions.setDataModeVariable({ dataModeVariable: DataModeVariable.Discharge }));
        break;
      case DataModeVariable.Precipitation:
        this.store.dispatch(WatershedActions.setDataModeVariable({ dataModeVariable: DataModeVariable.Precipitation }));
        break;
      default:
      case DataModeVariable.WaterLevel:
        this.store.dispatch(WatershedActions.setDataModeVariable({ dataModeVariable: DataModeVariable.WaterLevel }));
        break;
    }
  }
}
