<!-- eslint-disable @angular-eslint/template/no-negated-async -->
<ion-header>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-back-button icon="arrow-back-outline" [text]="'CATCHMENT' | translate" (click)="closeStation()"></ion-back-button>
        </ion-buttons>
        <ion-buttons slot="end">
            <ion-button (click)="resetToIslandsView()"><ion-icon name="home"></ion-icon></ion-button>
            <ion-button appShareUrl="station">
                <ion-icon name="share-outline" slot="icon-only"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content appSuppressScrollEvent class="station">
    <ion-header collapse="condense">
        <ion-toolbar>
            <ion-title class="--h1" [innerHTML]="(station$ | async)?.id + ' - ' + (station$ | async)?.name"></ion-title>
        </ion-toolbar>
    </ion-header>

    <div class="station__content">
        @if ((station$ | async)?.type === "1") {
            <p
                class="station__content__name --p1"
                [innerHTML]="
                    (station$ | async)?.lastReadWL
                        ? ('LAST_UPDATE'
                          | translate: { date: (station$ | async)?.lastReadWL | date: 'dd/MM/YY HH:mm' : translateService.defaultLang })
                        : (station$ | async)?.lastReadWL
                "
            ></p>
        }

        @if ((station$ | async)?.type === "0") {
            <p
                class="station__content__name --p1"
                [innerHTML]="
                    (station$ | async)?.lastReadR60
                        ? ('LAST_UPDATE'
                          | translate: { date: (station$ | async)?.lastReadR60 | date: 'dd/MM/YY HH:mm' : translateService.defaultLang })
                        : (station$ | async)?.lastReadR60
                "
            ></p>
        }

        @if ((station$ | async)?.type === "2") {
            <p
                class="station__content__name --p1"
                [innerHTML]="
                    (station$ | async)?.lastReadR60
                        ? ('LAST_UPDATE_METEO'
                          | translate: { date: (station$ | async)?.lastReadR60 | date: 'dd/MM/YY HH:mm' : translateService.defaultLang })
                        : (station$ | async)?.lastReadR60
                "
            ></p>
            <p
                class="station__content__name --p1"
                [innerHTML]="
                    (station$ | async)?.lastReadWL
                        ? ('LAST_UPDATE_HIDRO'
                          | translate: { date: (station$ | async)?.lastReadWL | date: 'dd/MM/YY HH:mm' : translateService.defaultLang })
                        : (station$ | async)?.lastReadWL
                "
            ></p>
        }

        @if ((station$ | async)?.type === "1" || (station$ | async)?.type === "2") {
            <p class="station__content__title --h2" [innerHTML]="'WATER_LEVEL' | translate"></p>

            <app-chart
                chartType="line"
                [alertLevels]="(stationData$ | async)?.alertLevels"
                [data]="(stationData$ | async)?.waterLevel"
            ></app-chart>
        }

        @if ((station$ | async)?.type === "0" || (station$ | async)?.type === "2") {
            <p class="station__content__title --h2" [innerHTML]="'STATION_PAGE.PRECIPITATION' | translate"></p>

            <!-- Hack to allow for straight top borders without touching the app-chart component -->
            <div>
                <app-chart
                    chartType="bar"
                    [data]="stationPrecipitationData$ | async"
                    [precipitationLast1h]="
                        'PRECIPITATION_UNIT'
                            | translate: { value: (station$ | async)?.precipitationLast1h | number: '1.0-1' : translateService.defaultLang }
                    "
                    [precipitationLast24h]="
                        'PRECIPITATION_UNIT'
                            | translate
                                : { value: (station$ | async)?.precipitationLast24h | number: '1.0-1' : translateService.defaultLang }
                    "
                ></app-chart>
            </div>

            <!-- If there's wind data show graph for wind. If not, don't.-->
            @if (hasWindData$ | async) {
                @if (hasWindGustData$ | async) {
                    <p class="station__content__title --h2" [innerHTML]="'STATION_PAGE.WIND_GUST' | translate"></p>
                } @else {
                    <p class="station__content__title --h2" [innerHTML]="'STATION_PAGE.WIND' | translate"></p>
                }
                <div class="station__content__card">
                    <app-empty-data [display]="!(emptyWindData$ | async)" [text]="'SENSOR_NO_DATA' | translate"></app-empty-data>
                    <ion-card class="station__content__card__summary border__bottom">
                        <img src="assets/images/wind.png" />
                        <div class="station__content__card__summary__values">
                            <div class="station__content__card__summary__value">
                                @if (hasWindGustData$ | async) {
                                    <p
                                        class="station__content__card__summary__title --h1"
                                        [innerHTML]="
                                            'WIND_UNIT'
                                                | translate: { value: windGust$ | async | number: '1.0-2' : translateService.defaultLang }
                                        "
                                    ></p>
                                } @else {
                                    <p
                                        class="station__content__card__summary__title --h1"
                                        [innerHTML]="
                                            'WIND_UNIT'
                                                | translate: { value: wind$ | async | number: '1.0-2' : translateService.defaultLang }
                                        "
                                    ></p>
                                }
                                <p
                                    class="station__content__card__summary__subtitle --h3"
                                    [innerHTML]="'STATION_PAGE.LAST_READING' | translate"
                                ></p>
                            </div>
                            <div class="station__content__card__summary__value" style="padding-left: calc(var(--space) * 2)">
                                @if (hasWindGustData$ | async) {
                                    @if (hasWindDirectionData$ | async) {
                                        <div style="display: flex; flex-direction: row; align-items: center; justify-content: flex-start">
                                            <img
                                                *ngIf="(windGust$ | async) < WIND_SPEED_LEVELS.WIND_CALM"
                                                src="assets/images/windDir_0.png"
                                                [class]="'no__margin__icon'"
                                            />
                                            <img
                                                *ngIf="
                                                    (windGust$ | async) >= WIND_SPEED_LEVELS.WIND_CALM &&
                                                    (windGust$ | async) < WIND_SPEED_LEVELS.WIND_WEAK
                                                "
                                                src="assets/images/windDir_1.png"
                                                [class]="'no__margin__icon rotate-' + (windDirection$ | async)"
                                            />
                                            <img
                                                *ngIf="
                                                    (windGust$ | async) >= WIND_SPEED_LEVELS.WIND_WEAK &&
                                                    (windGust$ | async) < WIND_SPEED_LEVELS.WIND_MODERATED
                                                "
                                                src="assets/images/windDir_2.png"
                                                [class]="'no__margin__icon rotate-' + (windDirection$ | async)"
                                            />
                                            <img
                                                *ngIf="
                                                    (windGust$ | async) >= WIND_SPEED_LEVELS.WIND_MODERATED &&
                                                    (windGust$ | async) < WIND_SPEED_LEVELS.WIND_STRONG
                                                "
                                                src="assets/images/windDir_3.png"
                                                [class]="'no__margin__icon rotate-' + (windDirection$ | async)"
                                            />
                                            <img
                                                *ngIf="(windGust$ | async) >= WIND_SPEED_LEVELS.WIND_STRONG"
                                                src="assets/images/windDir_4.png"
                                                [class]="'no__margin__icon rotate-' + (windDirection$ | async)"
                                            />
                                            <p
                                                class="station__content__card__summary__title --h1"
                                                [innerHTML]="windDirection$ | async | windDirectionName"
                                            ></p>
                                        </div>
                                    }
                                    <p
                                        *ngIf="(windGust$ | async) < WIND_SPEED_LEVELS.WIND_CALM"
                                        class="station__content__card__summary__subtitle --h3"
                                        [innerHTML]="'STATION_PAGE.WIND_CALM' | translate"
                                    ></p>
                                    <p
                                        *ngIf="
                                            (windGust$ | async) >= WIND_SPEED_LEVELS.WIND_CALM &&
                                            (windGust$ | async) < WIND_SPEED_LEVELS.WIND_WEAK
                                        "
                                        class="station__content__card__summary__subtitle --h3"
                                        [innerHTML]="'STATION_PAGE.WIND_WEAK' | translate"
                                    ></p>
                                    <p
                                        *ngIf="
                                            (windGust$ | async) >= WIND_SPEED_LEVELS.WIND_WEAK &&
                                            (windGust$ | async) < WIND_SPEED_LEVELS.WIND_MODERATED
                                        "
                                        class="station__content__card__summary__subtitle --h3"
                                        [innerHTML]="'STATION_PAGE.WIND_MODERATED' | translate"
                                    ></p>
                                    <p
                                        *ngIf="
                                            (windGust$ | async) >= WIND_SPEED_LEVELS.WIND_MODERATED &&
                                            (windGust$ | async) < WIND_SPEED_LEVELS.WIND_STRONG
                                        "
                                        class="station__content__card__summary__subtitle --h3"
                                        [innerHTML]="'STATION_PAGE.WIND_STRONG' | translate"
                                    ></p>
                                    <p
                                        *ngIf="(windGust$ | async) >= WIND_SPEED_LEVELS.WIND_STRONG"
                                        class="station__content__card__summary__subtitle --h3"
                                        [innerHTML]="'STATION_PAGE.WIND_VERY_STRONG' | translate"
                                    ></p>
                                } @else {
                                    @if (hasWindDirectionData$ | async) {
                                        <div style="display: flex; flex-direction: row; align-items: center; justify-content: flex-start">
                                            <img
                                                *ngIf="(wind$ | async) < WIND_SPEED_LEVELS.WIND_CALM"
                                                src="assets/images/windDir_0.png"
                                                [class]="'no__margin__icon'"
                                            />
                                            <img
                                                *ngIf="
                                                    (wind$ | async) >= WIND_SPEED_LEVELS.WIND_CALM &&
                                                    (wind$ | async) < WIND_SPEED_LEVELS.WIND_WEAK
                                                "
                                                src="assets/images/windDir_1.png"
                                                [class]="'no__margin__icon rotate-' + (windDirection$ | async)"
                                            />
                                            <img
                                                *ngIf="
                                                    (wind$ | async) >= WIND_SPEED_LEVELS.WIND_WEAK &&
                                                    (wind$ | async) < WIND_SPEED_LEVELS.WIND_MODERATED
                                                "
                                                src="assets/images/windDir_2.png"
                                                [class]="'no__margin__icon rotate-' + (windDirection$ | async)"
                                            />
                                            <img
                                                *ngIf="
                                                    (wind$ | async) >= WIND_SPEED_LEVELS.WIND_MODERATED &&
                                                    (wind$ | async) < WIND_SPEED_LEVELS.WIND_STRONG
                                                "
                                                src="assets/images/windDir_3.png"
                                                [class]="'no__margin__icon rotate-' + (windDirection$ | async)"
                                            />
                                            <img
                                                *ngIf="(wind$ | async) >= WIND_SPEED_LEVELS.WIND_STRONG"
                                                src="assets/images/windDir_4.png"
                                                [class]="'no__margin__icon rotate-' + (windDirection$ | async)"
                                            />
                                            <p
                                                class="station__content__card__summary__title --h1"
                                                [innerHTML]="windDirection$ | async | windDirectionName"
                                            ></p>
                                        </div>
                                    }
                                    <p
                                        *ngIf="(wind$ | async) < WIND_SPEED_LEVELS.WIND_CALM"
                                        class="station__content__card__summary__subtitle --h3"
                                        [innerHTML]="'STATION_PAGE.WIND_CALM' | translate"
                                    ></p>
                                    <p
                                        *ngIf="
                                            (wind$ | async) >= WIND_SPEED_LEVELS.WIND_CALM && (wind$ | async) < WIND_SPEED_LEVELS.WIND_WEAK
                                        "
                                        class="station__content__card__summary__subtitle --h3"
                                        [innerHTML]="'STATION_PAGE.WIND_WEAK' | translate"
                                    ></p>
                                    <p
                                        *ngIf="
                                            (wind$ | async) >= WIND_SPEED_LEVELS.WIND_WEAK &&
                                            (wind$ | async) < WIND_SPEED_LEVELS.WIND_MODERATED
                                        "
                                        class="station__content__card__summary__subtitle --h3"
                                        [innerHTML]="'STATION_PAGE.WIND_MODERATED' | translate"
                                    ></p>
                                    <p
                                        *ngIf="
                                            (wind$ | async) >= WIND_SPEED_LEVELS.WIND_MODERATED &&
                                            (wind$ | async) < WIND_SPEED_LEVELS.WIND_STRONG
                                        "
                                        class="station__content__card__summary__subtitle --h3"
                                        [innerHTML]="'STATION_PAGE.WIND_STRONG' | translate"
                                    ></p>
                                    <p
                                        *ngIf="(wind$ | async) >= WIND_SPEED_LEVELS.WIND_STRONG"
                                        class="station__content__card__summary__subtitle --h3"
                                        [innerHTML]="'STATION_PAGE.WIND_VERY_STRONG' | translate"
                                    ></p>
                                }
                            </div>
                        </div>
                    </ion-card>
                    <ion-card class="station__content__card__hours">
                        @if (hasWindGustData$ | async) {
                            @for (windGustHour of windGustHours$ | async; track $index) {
                                <div class="station__content__card__hours__item">
                                    <p class="--small" [innerHTML]="windGustHour.timestamp"></p>
                                    @if (windDirectionHours$ | async) {
                                        <img
                                            *ngIf="windGustHour.value < WIND_SPEED_LEVELS.WIND_CALM"
                                            src="assets/images/windDir_0.png"
                                            [class]="'wind-img'"
                                        />
                                        <img
                                            *ngIf="
                                                windGustHour.value >= WIND_SPEED_LEVELS.WIND_CALM &&
                                                windGustHour.value < WIND_SPEED_LEVELS.WIND_WEAK
                                            "
                                            src="assets/images/windDir_1.png"
                                            [class]="'wind-img rotate-' + (windDirectionHours$ | async)[$index].value"
                                        />
                                        <img
                                            *ngIf="
                                                windGustHour.value >= WIND_SPEED_LEVELS.WIND_WEAK &&
                                                windGustHour.value < WIND_SPEED_LEVELS.WIND_MODERATED
                                            "
                                            src="assets/images/windDir_2.png"
                                            [class]="'wind-img rotate-' + (windDirectionHours$ | async)[$index].value"
                                        />
                                        <img
                                            *ngIf="
                                                windGustHour.value >= WIND_SPEED_LEVELS.WIND_MODERATED &&
                                                windGustHour.value < WIND_SPEED_LEVELS.WIND_STRONG
                                            "
                                            src="assets/images/windDir_3.png"
                                            [class]="'wind-img rotate-' + (windDirectionHours$ | async)[$index].value"
                                        />
                                        <img
                                            *ngIf="windGustHour.value >= WIND_SPEED_LEVELS.WIND_STRONG"
                                            src="assets/images/windDir_4.png"
                                            [class]="'wind-img rotate-' + (windDirectionHours$ | async)[$index].value"
                                        />
                                        <p
                                            class="--small"
                                            [innerHTML]="(windDirectionHours$ | async)[$index].value | windDirectionName"
                                        ></p>
                                    }
                                    <p
                                        class="--small --bold"
                                        [innerHTML]="
                                            'WIND_UNIT'
                                                | translate: { value: windGustHour.value | number: '1.0-2' : translateService.defaultLang }
                                        "
                                    ></p>
                                    <p
                                        *ngIf="windGustHour.value < WIND_SPEED_LEVELS.WIND_CALM"
                                        class="--small"
                                        [innerHTML]="'STATION_PAGE.WIND_CALM' | translate"
                                    ></p>
                                    <p
                                        *ngIf="
                                            windGustHour.value >= WIND_SPEED_LEVELS.WIND_CALM &&
                                            windGustHour.value < WIND_SPEED_LEVELS.WIND_WEAK
                                        "
                                        class="--small"
                                        [innerHTML]="'STATION_PAGE.WIND_WEAK' | translate"
                                    ></p>
                                    <p
                                        *ngIf="
                                            windGustHour.value >= WIND_SPEED_LEVELS.WIND_WEAK &&
                                            windGustHour.value < WIND_SPEED_LEVELS.WIND_MODERATED
                                        "
                                        class="--small"
                                        [innerHTML]="'STATION_PAGE.WIND_MODERATED' | translate"
                                    ></p>
                                    <p
                                        *ngIf="
                                            windGustHour.value >= WIND_SPEED_LEVELS.WIND_MODERATED &&
                                            windGustHour.value < WIND_SPEED_LEVELS.WIND_STRONG
                                        "
                                        class="--small"
                                        [innerHTML]="'STATION_PAGE.WIND_STRONG' | translate"
                                    ></p>
                                    <p
                                        *ngIf="windGustHour.value >= WIND_SPEED_LEVELS.WIND_STRONG"
                                        class="--small"
                                        [innerHTML]="'STATION_PAGE.WIND_VERY_STRONG' | translate"
                                    ></p>
                                </div>
                            }
                        } @else if (hasWindData$ | async) {
                            @for (value of windHours$ | async; track $index) {
                                <div class="station__content__card__hours__item">
                                    <p class="--small" [innerHTML]="value.timestamp"></p>
                                    @if (windDirectionHours$ | async) {
                                        <img
                                            *ngIf="value.value < WIND_SPEED_LEVELS.WIND_CALM"
                                            src="assets/images/windDir_0.png"
                                            [class]="'wind-img'"
                                        />
                                        <img
                                            *ngIf="value.value >= WIND_SPEED_LEVELS.WIND_CALM && value.value < WIND_SPEED_LEVELS.WIND_WEAK"
                                            src="assets/images/windDir_1.png"
                                            [class]="'wind-img rotate-' + (windDirectionHours$ | async)[$index].value"
                                        />
                                        <img
                                            *ngIf="
                                                value.value >= WIND_SPEED_LEVELS.WIND_WEAK && value.value < WIND_SPEED_LEVELS.WIND_MODERATED
                                            "
                                            src="assets/images/windDir_2.png"
                                            [class]="'wind-img rotate-' + (windDirectionHours$ | async)[$index].value"
                                        />
                                        <img
                                            *ngIf="
                                                value.value >= WIND_SPEED_LEVELS.WIND_MODERATED &&
                                                value.value < WIND_SPEED_LEVELS.WIND_STRONG
                                            "
                                            src="assets/images/windDir_3.png"
                                            [class]="'wind-img rotate-' + (windDirectionHours$ | async)[$index].value"
                                        />
                                        <img
                                            *ngIf="value.value >= WIND_SPEED_LEVELS.WIND_STRONG"
                                            src="assets/images/windDir_4.png"
                                            [class]="'wind-img rotate-' + (windDirectionHours$ | async)[$index].value"
                                        />
                                        <p
                                            class="--small"
                                            [innerHTML]="(windDirectionHours$ | async)[$index].value | windDirectionName"
                                        ></p>
                                    }
                                    <p
                                        class="--small --bold"
                                        [innerHTML]="
                                            'WIND_UNIT' | translate: { value: value.value | number: '1.0-2' : translateService.defaultLang }
                                        "
                                    ></p>
                                    <p
                                        *ngIf="value.value < WIND_SPEED_LEVELS.WIND_CALM"
                                        class="--small"
                                        [innerHTML]="'STATION_PAGE.WIND_CALM' | translate"
                                    ></p>
                                    <p
                                        *ngIf="value.value >= WIND_SPEED_LEVELS.WIND_CALM && value.value < WIND_SPEED_LEVELS.WIND_WEAK"
                                        class="--small"
                                        [innerHTML]="'STATION_PAGE.WIND_WEAK' | translate"
                                    ></p>
                                    <p
                                        *ngIf="value.value >= WIND_SPEED_LEVELS.WIND_WEAK && value.value < WIND_SPEED_LEVELS.WIND_MODERATED"
                                        class="--small"
                                        [innerHTML]="'STATION_PAGE.WIND_MODERATED' | translate"
                                    ></p>
                                    <p
                                        *ngIf="
                                            value.value >= WIND_SPEED_LEVELS.WIND_MODERATED && value.value < WIND_SPEED_LEVELS.WIND_STRONG
                                        "
                                        class="--small"
                                        [innerHTML]="'STATION_PAGE.WIND_STRONG' | translate"
                                    ></p>
                                    <p
                                        *ngIf="value.value >= WIND_SPEED_LEVELS.WIND_STRONG"
                                        class="--small"
                                        [innerHTML]="'STATION_PAGE.WIND_VERY_STRONG' | translate"
                                    ></p>
                                </div>
                            }
                        }
                    </ion-card>
                    <div class="--straight__border__top">
                        @if (hasWindGustData$ | async) {
                            <app-chart
                                chartType="line"
                                [data]="(stationData$ | async)?.windGust"
                                [dataLabel]="'STATION_PAGE.WIND_GUST' | translate"
                                [secondaryData]="(stationData$ | async)?.wind"
                                [secondaryDataLabel]="'STATION_PAGE.WIND' | translate"
                                [varType]="'WindSpeed'"
                                [windSpeedAlertLevels]="windSpeedAlertLevels"
                            ></app-chart>
                        } @else {
                            <app-chart
                                chartType="line"
                                [data]="(stationData$ | async)?.wind"
                                [dataLabel]="'STATION_PAGE.WIND' | translate"
                                [varType]="'WindSpeed'"
                                [windSpeedAlertLevels]="windSpeedAlertLevels"
                            ></app-chart>
                        }
                    </div>
                </div>
            }

            <!-- If there's temeprature data show station__content__card for temperature. If not, don't.-->
            @if (hasTemperatureData$ | async) {
                <p class="station__content__title --h2" [innerHTML]="'STATION_PAGE.TEMPERATURE' | translate"></p>

                <div class="station__content__card">
                    <app-empty-data [display]="!(emptyTemperatureData$ | async)" [text]="'SENSOR_NO_DATA' | translate"></app-empty-data>
                    <ion-card class="station__content__card__summary border__bottom">
                        <img src="assets/images/temperature.png" />
                        <div>
                            <p
                                class="station__content__card__summary__title --h1"
                                [innerHTML]="
                                    'TEMPERATURE_UNIT'
                                        | translate: { value: temperature$ | async | number: '1.0-2' : translateService.defaultLang }
                                "
                            ></p>
                            <p
                                class="station__content__card__summary__subtitle --h3"
                                [innerHTML]="'STATION_PAGE.LAST_READING' | translate"
                            ></p>
                        </div>
                    </ion-card>

                    <ion-card class="station__content__card__hours">
                        @for (value of temperatureHours$ | async; track value.timestamp) {
                            <div class="station__content__card__hours__item">
                                <p class="--small" [innerHTML]="value.timestamp"></p>
                                <img src="assets/images/temperature.png" />
                                <p
                                    class="--small"
                                    [innerHTML]="
                                        'TEMPERATURE_UNIT'
                                            | translate: { value: value.value | number: '1.0-2' : translateService.defaultLang }
                                    "
                                ></p>
                            </div>
                        }
                    </ion-card>
                </div>
            }

            <!-- If there's humidity data show station__content__card for humidity. If not, don't.-->
            @if (hasHumidityData$ | async) {
                <p class="station__content__title --h2" [innerHTML]="'STATION_PAGE.HUMIDITY' | translate"></p>

                <div class="station__content__card">
                    <app-empty-data [display]="!(emptyHumidityData$ | async)" [text]="'SENSOR_NO_DATA' | translate"></app-empty-data>
                    <ion-card class="station__content__card__summary border__bottom">
                        <img src="assets/images/humidity.png" />
                        <div>
                            <p
                                class="station__content__card__summary__title --h1"
                                [innerHTML]="
                                    'HUMIDITY_UNIT'
                                        | translate: { value: humidity$ | async | number: '1.0-2' : translateService.defaultLang }
                                "
                            ></p>
                            <p
                                class="station__content__card__summary__subtitle --h3"
                                [innerHTML]="'STATION_PAGE.LAST_READING' | translate"
                            ></p>
                        </div>
                    </ion-card>

                    <ion-card class="station__content__card__hours">
                        @for (value of humidityHours$ | async; track value.timestamp) {
                            <div class="station__content__card__hours__item">
                                <p class="--small" [innerHTML]="value.timestamp"></p>
                                <img src="assets/images/humidity.png" />
                                <p
                                    class="--small"
                                    [innerHTML]="
                                        'HUMIDITY_UNIT' | translate: { value: value.value | number: '1.0-2' : translateService.defaultLang }
                                    "
                                ></p>
                            </div>
                        }
                    </ion-card>
                </div>
            }
        }
    </div>
</ion-content>
