<ion-card class="data-card --clickable" [class.--hovered]="hovered">
    <div class="data-card__content">
        <div class="data-card__content__title">
            <p class="--h2" [innerHTML]="subtitle"></p>
        </div>
        <div class="data-card__content__split">
            <div class="data-card__content__split__item">
                @switch (selectedDataModeVariable) {
                    @case (dataModeVariable.Precipitation) {
                        <p class="data-card__content__split__item__title" [innerHTML]="'DATA-CARD.OBSERVED_PRECIPITATION' | translate"></p>
                    }
                    @case (dataModeVariable.WaterLevel) {
                        <p class="data-card__content__split__item__title" [innerHTML]="'DATA-CARD.OBSERVED_WATER_LEVEL' | translate"></p>
                    }
                    @case (dataModeVariable.Discharge) {
                        <p class="data-card__content__split__item__title" [innerHTML]="'DATA-CARD.OBSERVED_DISCHARGE' | translate"></p>
                    }
                }
                <p class="data-card__content__split__item__value" [innerHTML]="title"></p>
                <div class="data-card__content__bottom">
                    <p
                        class="data-card__content__bottom__level --small"
                        [class]="'--' + status"
                        [innerHTML]="status ? ('ALERT_LEVEL.' + status | translate) : '-'"
                    ></p>
                </div>
            </div>
            @if (statusPredicted && statusPredicted > 1) {
                <div class="vl"></div>
                <div class="data-card__content__split__item">
                    <p
                        class="data-card__content__split__item__title --italic"
                        [innerHTML]="'DATA-CARD.PREDICTED_DISCHARGE' | translate"
                    ></p>
                    <p class="data-card__content__split__item__value --italic" [innerHTML]="dischargePredicted"></p>
                    <div class="data-card__content__bottom">
                        <p
                            class="data-card__content__bottom__level --small --italic"
                            [class]="'--' + statusPredicted"
                            [innerHTML]="statusPredicted ? ('ALERT_LEVEL.' + statusPredicted | translate) : '-'"
                        ></p>
                    </div>
                </div>
            }
        </div>
    </div>
    <!-- } -->
</ion-card>
